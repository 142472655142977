import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { useToast } from "vue-toast-notification";
import { useConfigStore } from "@/stores/ConfigStore";
import axios from "axios";
import { usePlayerStore } from "@/stores/PlayerStore";
import moment from "moment";
import { useAuthStore } from "./AuthStore";

export const usePlanetsStore = defineStore("planets", () => {

  const $toast = useToast({ position: "top-right" });
  const configStore = useConfigStore();
  const playerStore = usePlayerStore();
  const authStore = useAuthStore();

  const planets = ref({});
  const favourites = ref([]);
  const missionPlanets = ref([]);
  // const manualMissionPlanet = ref(null);

  function planet(planetId) {
      return planets.value[planetId] || null;
  }

  function planetOwner(planet_id) {
    const _planet = planet(planet_id);
    return _planet ? _planet.owner : "";
  }

  // function planetPool(planet_id) {
  //   const _planet = planet(planet_id);
  //   return _planet ? _planet.owner : "";
  // }
  //
  // function planetMaxPool(planet_id) {
  //   const _planet = planet(planet_id);
  //   return _planet ? _planet.owner : "";
  // }

  function planetPoolDisplay(planet_id) {
    const _planet = planet(planet_id);
    const now = moment.utc().unix();

    if(!_planet) {
      return "";
    }

    let max = _planet.max_pool;
    //check if next_reset has passed
    let pool = now > _planet.next_reset ? _planet.max_pool : _planet.pool;

    return Math.floor(parseFloat(pool)).toLocaleString()
        + " / "
        + Math.floor(parseFloat(max)).toLocaleString();
  }

  function planetName(planet_id) {
    const planet = planetCoordinates(planet_id);
    return planet ? `${planet.spacestation}-${planet.quad}-${planet.sector}-${planet.system}-${planet.planet}` : "";
  }

  function planetSector(planet_id) {
    return planetCoordinates(planet_id)?.sector;
  }

  function planetSpacestation(planet_id) {
    return planetCoordinates(planet_id)?.spacestation;
  }

  function planetCoordinates(planet_id) {
    if(!planet_id) {
      return null;
    }
    // console.log(planet_id);
    planet_id = parseInt(planet_id);
    const spacestation_multiplier = 16384;
    const quad_multiplier = 4096;
    const sector_multiplier = 128;
    const system_multiplier = 4;
    const planet_multiplier = 1;

    const spacestation = Math.floor(planet_id / spacestation_multiplier);
    const quad = Math.floor( (planet_id - (spacestation * spacestation_multiplier)) / quad_multiplier );
    const sector = Math.floor( (planet_id - (spacestation * spacestation_multiplier) - (quad * quad_multiplier)) / sector_multiplier );
    const system = Math.floor( (planet_id - (spacestation * spacestation_multiplier) - (quad * quad_multiplier) - (sector * sector_multiplier)) / system_multiplier );
    const planet = Math.floor( (planet_id - (spacestation * spacestation_multiplier) - (quad * quad_multiplier) - (sector * sector_multiplier) - (system * system_multiplier)) / planet_multiplier );

    return {
      "spacestation": spacestation,
      "quad": quad,
      "sector": sector,
      "system": system,
      "planet": planet,
    }
  }

  function getPlanets(planetIds) {
    if(Array.isArray(planetIds) && planetIds.length > 0) {
      planetIds.forEach((planetId, index) => {
        setTimeout(() => {
          getPlanet(planetId);
        }, index*150);
      });
    }
  }

  async function getPlanet(planetId, callback = undefined) {
    // Early return if planet already fetched in last 5 seconds
    if(typeof planets.value[planetId] !== "undefined" && planets.value[planetId].lastUpdate + 5 > moment.utc().unix()) {
      return (callback && typeof callback === "function")
        ? callback(planets.value[planetId])
        : planets.value[planetId];
    }
    // Otherwise fetch fresh data
    await axios.post(configStore.apiNode + "/v1/chain/get_table_rows", {
      json: true,
      code: configStore.contract,
      table: "planets",
      scope: configStore.contract,
      lower_bound: planetId,
      upper_bound: planetId,
      index_position: "primary",
      key_type: "i64",
      reverse: false,
      show_payer: false,
      limit: 1,
    })
      .then((response) => {
        console.debug("planet "+planetId, response.data);
        if(typeof response.data.rows !== "undefined" && response.data.rows.length > 0) {
          const fetchedPlanet = response.data.rows[0]
          fetchedPlanet.lastUpdate = moment.utc().unix();
          planets.value[planetId] = fetchedPlanet;
          if(callback && typeof callback === "function") {
            return callback(fetchedPlanet);
          }
        }
      })
      .catch((error) => {
        // $toast.error(error.message);
        console.error(error);
      });
  }

  function coordinatesToId(spacestation, quadrant, sector, system, planet) {
    if(typeof spacestation === "undefined" || spacestation === null || spacestation === "" ||
      typeof quadrant === "undefined" || quadrant === null || quadrant === "" ||
      typeof sector === "undefined" || sector === null || sector === "" ||
      typeof system === "undefined" || system === null || system === "" ||
      typeof planet === "undefined" || planet === null || planet === ""
    ) {
      return null;
    }

    let _spacestation = spacestation * 16384;
    let _quadrant = quadrant * 4096 ;
    let _sector = sector * 128;
    let _system = system * 4;
    let _planet = planet * 1;
    return _spacestation + _quadrant + _sector + _system + _planet;
  }

  async function getMissionPlanets(spacestation, quadrant, sector, system) {
    const lowerBound = coordinatesToId(spacestation, quadrant, sector, system, 0);
    const upperBound = parseInt(lowerBound, 10) + 3;

    await axios.post(configStore.apiNode + "/v1/chain/get_table_rows", {
      json: true,
      code: configStore.contract,
      table: "planets",
      scope: configStore.contract,
      lower_bound: lowerBound,
      upper_bound: upperBound,
      index_position: "primary",
      key_type: "i64",
      reverse: false,
      show_payer: false,
      limit: 4,
    })
      .then((response) => {
        console.debug("mission_planets ", response.data);
        if(typeof response.data.rows !== "undefined" && response.data.rows.length > 0) {
          missionPlanets.value = response.data.rows;
        }
      })
      .catch((error) => {
        // $toast.error(error.message); //dont need to see this error
        console.error(error);
      });
  }


  // async function getManualMissionPlanet(planetId) {
  //   manualMissionPlanet.value = null;
  //   await getPlanet(planetId, (planet) => {
  //     manualMissionPlanet.value = planet;
  //   });
  // }


  function manuallyUpdateClaimedPlanet(planetId, player) {
      planets.value[planetId].owner = player;
      // setTimeout(() => {
      //   getPlanet(planetId);
      // }, 8000);
  }

  function resetFavourites() {
    favourites.value = [];
  }

  function saveFavouritesToStorage() {
    window.localStorage.setItem(`pirateship-${authStore.wallet}-favourite-planets`, JSON.stringify(favourites.value));
  }

  function initFromStorage() {
    // Get persistent stuff from local storage
    const storedFavourites = window.localStorage.getItem(`pirateship-${authStore.wallet}-favourite-planets`);
    if (storedFavourites) {
      favourites.value = JSON.parse(storedFavourites);
    }
  }

  return {
    getPlanet,
    getPlanets,
    planetName,
    manuallyUpdateClaimedPlanet,
    planetCoordinates,
    planetOwner,
    planetSector,
    planetSpacestation,
    getMissionPlanets,
    planetPoolDisplay,
    // getManualMissionPlanet,
    planets,
    planet,
    missionPlanets,
    initFromStorage,
    resetFavourites
    // manualMissionPlanet
  };
});
