import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { useToast } from "vue-toast-notification";
import { useConfigStore } from "@/stores/ConfigStore";
import { useAuthStore } from "@/stores/AuthStore";
import { usePlanetsStore } from "@/stores/PlanetsStore";
import axios from "axios";
import { usePlayerStore } from "@/stores/PlayerStore";
import moment from "moment/moment";
import bus from "@/eventBus";
import { useRentalsStore } from "./RentalsStore";

export const useShipsStore = defineStore("ships", () => {

  const $toast = useToast({ position: "top-right" });
  const configStore = useConfigStore();
  const playerStore = usePlayerStore();
  const planetsStore = usePlanetsStore();
  const rentalsStore = useRentalsStore();
  const authStore = useAuthStore();

  const ships = ref([]);
  const presets = ref([]);

  function resetShips() {
    ships.value = [];
  }

  async function getShips(callback = undefined) {
    const player = playerStore.player.player || null;
    if(!player) {
      console.warn("no player");
      return;
    }
    axios.post(configStore.apiNode + "/v1/chain/get_table_rows", {
      json: true,
      code: configStore.contract,
      table: "starshipdata",
      scope: configStore.contract,
      lower_bound: playerStore.player.player,
      upper_bound: playerStore.player.player,
      index_position: 2,
      key_type: "name",
      reverse: false,
      limit: 12,
      show_payer: false
    })
      .then((response) => {
        console.debug("ships", response.data);
        if(typeof response.data.rows !== "undefined" && response.data.rows.length > 0) {

          // TESTING: Set random rarities on ships
          // response.data.rows.forEach(row => {
          //   row.rarity = Math.floor(Math.random() * 7);
          //   ships.value.push(row);
          // });

          response.data.rows.forEach((shipData) => {
            // Add our loading state to the newly fetched ship
            shipData.loading = true;
            shipData.lastRefresh = moment.utc().unix();
            // Get the index of the ship and replace it
            const index = ships.value.findIndex(ship => ship.key === shipData.key);
            if(index >= 0) {
              ships.value.splice(index, 1, shipData);
            } else {
              ships.value.push(shipData);
            }
            bus.$emit("shipRefreshed", shipData.key);
            // unset loading state after 2 secs (just so there is a visual delay)
            setTimeout(() => {
              updateShipAttribute(shipData.key, "loading", false);
            }, 1000);
          });

          getRentedShips();

          if(callback && typeof callback === "function") {
            return callback(response.data.rows);
          }
        }
      })
      .catch((error) => {
        // $toast.error(error.message);
        console.error(error);
      });
  }

  function getRentedShips() {
    const rentedShips = rentalsStore.rentedToPlayer(authStore.wallet);
    if(rentedShips.length > 0) {
      // Delay between multiple requests
      const delay = 150;
      rentedShips.forEach((rental, index) => {
        setTimeout(() => {
          getShip(rental.key);
        }, delay*index);
      });
    }
  }

  function updateShipAttribute(shipId, attributeName, attributeValue) {
    const index = ships.value.findIndex(ship => ship.key === shipId);
    if(index >= 0) {
      ships.value[index][attributeName] = attributeValue;
    }
  }

  async function getShip(shipId, callback = undefined) {
    // If the ship exists, then set loading to true
    const index = ships.value.findIndex(ship => ship.key === shipId);
    if(index >= 0) {
      ships.value[index].loading = true;
    }
    // Make the API request
    await axios.post(configStore.apiNode + "/v1/chain/get_table_rows", {
      json: true,
      code: configStore.contract,
      table: "starshipdata",
      scope: configStore.contract,
      lower_bound: shipId,
      upper_bound: shipId,
      index_position: "primary",
      key_type: "i64",
      reverse: false,
      show_payer: false,
      limit: 1
    })
      .then((response) => {
        console.debug("ship "+shipId, response.data);
        if(typeof response.data.rows !== "undefined" && response.data.rows.length > 0) {
          // Add our loading state to the newly fetched ship
          const updatedShip = response.data.rows[0];
          updatedShip.loading = true;
          updatedShip.lastRefresh = moment.utc().unix();

          // Get the index of the ship and replace it
          const index = ships.value.findIndex(ship => ship.key === shipId);
          if(index >= 0) {
            ships.value.splice(index, 1, response.data.rows[0]);
            bus.$emit("shipRefreshed", shipId);
            // unset loading state after 2 secs (just so there is a visual delay)
            setTimeout(() => {
              updateShipAttribute(shipId, "loading", false);
            }, 1500);
            // callback, if set
            if(callback && typeof callback === "function") {
              return callback(response.data.rows[0]);
            }
          } else {
            ships.value.push(response.data.rows[0]);
            bus.$emit("shipRefreshed", shipId);
            // unset loading state after 2 secs (just so there is a visual delay)
            setTimeout(() => {
              updateShipAttribute(shipId, "loading", false);
            }, 1500);
            // callback, if set
            if(callback && typeof callback === "function") {
              return callback(response.data.rows[0]);
            }
          }

        }
        return response.data.rows[0];
      })
      .catch((error) => {
        // $toast.error(error.message);
        console.error(error);
      });
  }

  // manually update some ship info immediately after mainPlanet, so we can show it's status as returning, while we wait for the set Timeout to fetch the updated arrival time + countdown
  function manuallyUpdateReturningShip(planetId, shipId, callback = undefined) {
    const now = moment.utc().unix();
    const planetSector = planetsStore.planetSector(planetId);
    const parsecs = (parseFloat(planetSector) + 1) * 0.36000001430511475;//TODO: Get parsec from sshipbalance table and store
    const shipIndex = ships.value.findIndex(ship => shipId === ship.key);

    if(shipIndex >= 0) {
        ships.value[shipIndex].planet  = 0;
        ships.value[shipIndex].start  = now;
        ships.value[shipIndex].end  = Math.floor(parseInt(now) + (parsecs * parseFloat(ships.value[shipIndex].speed) * 60));
        if(typeof callback !== "undefined" && typeof callback === "function") {
          callback(shipId);
        }
    }
  }

  // manually update some ship info immediately after ss change, so we can show it's status as travelling, while we wait for the set Timeout to fetch the updated arrival time + countdown
  function manuallyUpdateShipChangingStation(shipId, spacestationId) {
    const now = moment.utc().unix();
    const interdistance = 1; //TODO: Get this from the sshipbalance table in a new store
    const shipIndex = ships.value.findIndex(ship => shipId === ship.key);

    if(shipIndex >= 0) {
        ships.value[shipIndex].spacestation  = spacestationId;
        ships.value[shipIndex].planet  = 0;
        ships.value[shipIndex].start  = now;
        ships.value[shipIndex].end  = Math.floor(parseInt(now) + (interdistance * parseFloat(ships.value[shipIndex].speed) * 60));
        setTimeout(() => {
          getShip(shipId);
        }, 5000);
    }
  }

  // manually update some ship info immediately after ss change, so we can show it's status as travelling, while we wait for the set Timeout to fetch the updated arrival time + countdown
  function manuallyUpdateShipOnMission(shipId, planetId) {
    const now = moment.utc().unix();
    const planetSector = planetsStore.planetSector(planetId);
    const parsecs = (parseFloat(planetSector) + 1) * 0.36000001430511475; //TODO: Get parsec from sshipbalance table and store
    const shipIndex = ships.value.findIndex(ship => shipId === ship.key);

    if(shipIndex >= 0) {
      ships.value[shipIndex].planet  = planetId;
      ships.value[shipIndex].start  = now;
      ships.value[shipIndex].end  = Math.floor(parseInt(now) + (parsecs * parseFloat(ships.value[shipIndex].speed) * 60));
      setTimeout(() => {
        getShip(shipId);
      }, 5000);
    }
  }

  function ship(shipId) {
    const shipIndex = ships.value.findIndex(ship => shipId === ship.key);
    return ships.value[shipIndex] || false;
  }

  function playerShipIndex(shipId) {
    return ships.value.findIndex(ship => shipId === ship.key);
  }

  function getShipPreset(shipId) {
    return presets.value.find(preset => preset.shipId === shipId)?.planetId;
  }

  function getShipPresetName(shipId) {
    return planetsStore.planetName(getShipPreset(shipId)) || null;
  }

  function presetPlanetForAllShips( planetId) {
    if(ships.value.length > 0) {
      ships.value.forEach((ship) => {
        presetPlanetForShip(ship.key, planetId);
      });
    }
  }
  function presetPlanetForShip(shipId, planetId) {
    // save locally to presets
    const presetIndex = presets.value.findIndex(preset => preset.shipId === shipId);
    const newPreset = {
      shipId: shipId,
      planetId: planetId,
    };
    if(presetIndex >= 0) {
      presets.value[presetIndex] = newPreset;
    } else {
      presets.value.push(newPreset);
    }

    // Save whole presets object/array to localstorage with a player key
    savePresetsToStorage();
  }

  function savePresetsToStorage() {
    window.localStorage.setItem(`pirateship-${authStore.wallet}-presets`, JSON.stringify(presets.value));
  }

  function resetPresets() {
    presets.value = [];
    initFromStorage();
  }

  function initFromStorage() {
    // Get persistent stuff from local storage
    const storedPresets = window.localStorage.getItem(`pirateship-${authStore.wallet}-presets`);
    if (storedPresets) {
      presets.value = JSON.parse(storedPresets);
    }
  }

  return {
    presets,
    ships,
    ship,
    getShips,
    getShip,
    resetShips,
    resetPresets,
    manuallyUpdateReturningShip,
    manuallyUpdateShipChangingStation,
    manuallyUpdateShipOnMission,
    presetPlanetForShip,
    presetPlanetForAllShips,
    playerShipIndex,
    initFromStorage,
    getShipPreset,
    getShipPresetName,
  };
});
