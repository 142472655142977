<template>
  <table class="table table-borderless table-sm balances">
    <tbody>
    <tr>
      <th class="text-blue py-0">Starship</th>
      <td class="text-blue py-0">{{ helpers.roundToTwo(playerStore.starship) }}</td>
    </tr>
    <tr>
      <th class="text-purple py-0">Kyanite (staked)</th>
      <td class="text-purple py-0">{{ helpers.roundToTwo(playerStore.kyanite) }}</td>
    </tr>
    <tr>
      <th class="text-secondary py-0">Kyanite (wallet)</th>
      <td class="text-secondary py-0">{{ helpers.roundToTwo(accountStore.kyanite) }}</td>
    </tr>
    <tr>
      <th class="text-warning py-0">WAX</th>
      <td class="text-warning py-0">{{ helpers.roundToTwo(accountStore.wax) }}</td>
    </tr>
    </tbody>
  </table>
</template>

<script setup>
import { usePlayerStore } from "@/stores/PlayerStore";
import { useAccountStore } from "@/stores/AccountStore";
import helpers from "@/helpers/global-helpers";

const playerStore = usePlayerStore();
const accountStore = useAccountStore();

</script>

