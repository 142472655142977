<template>
  <div class="d-flex">
    <div v-if="currentlyEquippedComponent" class="me-3">
      <img :src="`/images/nfts/${currentlyEquippedComponent.template.template_id}.png`" style="width: 45px;height: auto;" class="img-fluid" :alt="`${currentlyEquippedComponent.data.rarity} ${currentlyEquippedComponent.data.name}`">
    </div>
    <div >
      <h3 class="mt-0">{{componentName}}</h3>
      <p class="help">Select a {{componentName}} to equip.</p>
    </div>

    <div class="ms-auto">
      <button class="btn btn-secondary" @click="emitBack"><i class="bi bi-chevron-left"></i> Back</button>
    </div>
  </div>

  <div class="position-relative">
    <div class="grid-component-chooser" style="min-height: 50px;">
      <ComponentChooserComponent v-for="asset in components"
                                 :key="asset.asset_id"
                                 :asset="asset"
                                 @equip="actionEquipComponent"
      ></ComponentChooserComponent>
    </div>
    <div class="alert alert-info w-100" v-if="components.length === 0">
      <p class="mb-0 text-center">No {{componentName}} components found!</p>
    </div>
    <div v-if="isLoading" class="loading-overlay text-center">
      <div class="lds-dual-ring mx-auto"></div>
      <p class="text-success text-center">Loading...</p>
    </div>
  </div>


</template>

<script setup>

import { computed, onMounted, ref } from "vue";
import componentsJSON from "@/config/components.json";
import axios from "axios";
import ComponentChooserComponent from "@/components/modals/ComponentsModal/ComponentChooserComponent.vue";
import { usePlayerActionsStore } from "@/stores/actions/PlayerActionsStore";

const playerActionsStore = usePlayerActionsStore();

const props = defineProps({
  equipped: {
    type: Array,
    default: []
  },
  ship: {
    type: Object,
    default: {}
  },
  componentType: {
    type: Number,
  },
  player: {
    type: String,
    default: "",
  },
});

const isLoading = ref(false);
const components = ref([]);

const emit = defineEmits(["unequip", "chooser", "equipped"]);

const componentName = computed(() => {
  return componentsJSON.find(item => item.key === props.componentType)?.name;
})

const currentlyEquippedComponent = computed(() => {
  return props.equipped.find(asset => asset.data.tdata === props.componentType.toString());
});

const actionEquipComponent = (asset_id) => {
  playerActionsStore.equipComponent(props.ship.key, asset_id)
    .then(() => {
      const equippedAsset = components.value.find(asset => asset.asset_id === asset_id);

      // Remove part from current chooser list
      removeAssetFromComponentsList(asset_id);

      // Update the ships equipped parts + assets
      emit("equipped", equippedAsset);
    }).catch(error => {
      console.error("sub error", error)
  });
};

const removeAssetFromComponentsList = (asset_id) => {
  const assetIndex = components.value.findIndex(asset => asset.asset_id === asset_id);
  components.value.splice(assetIndex, 1)
};

const emitBack = () => {
  emit("chooser", "index");
};

const fetchAssets = async () => {
  // console.log("fetchAssets", props.player, props.componentType);

  //init
  let page = 1;
  let more = true;
  let assets = [];
  isLoading.value = true;

  // build url
  // let url = "https://wax.api.atomicassets.io/atomicassets/v1/assets";
  let url = "https://aa-api-wax-mainnet-1.eu.aws.pink.gg/atomicassets/v1/assets";
  url += "?collection_name=starshipnfts";
  url += "&schema_name=component";
  url += "&owner="+props.player;
  url += "&data:text.tdata="+props.componentType
  url += "&limit=50";

  // We need a way to page through multiple pages if player has many NFTs!!
  while(more === true && page < 100) {
    // make request, and save to our assets ref
      await axios.get(url + "&page=" + page)
        .then((response) => {
          if(typeof response.data.data !== "undefined" && response.data.data.length > 0) {
            response.data.data.forEach(asset => {
              assets.push(asset)
            })
          } else {
            more = false;
            //sort by descending rarity
            assets.sort(function(a,b){
              return a.data.rdata > b.data.rdata ? -1 : 1;
            });
            components.value = assets;
            isLoading.value = false;
          }
        })
        .catch((error) => {
          console.error(error);
          more = false;
        })
        .finally(() => {
          page++;
        });
  }
}

onMounted(() => {
  if(process.env.NODE_ENV !== "production" && import.meta.env.VITE_SHOW_MOUNTED_DEBUG === "true") {
    console.debug("ComponentsChooser mounted");
  }

  fetchAssets();
});
</script>