<template>
  <Modal
    id="componentsModal"
    :title="modalTitle"
    :on-close="closeModal"
    class=""
    size-class="modal-lg modal-fullscreen-sm-down"
    :use-footer="false"
  >
    <template #body>
      <ComponentsIndex
        v-if="state==='index'"
        :ship="ship"
        :equipped-components="equipped"
        @chooser="setState"
      ></ComponentsIndex>
      <ComponentsChooser
        v-else
        :equipped="equipped"
        :ship="ship"
        :player="authStore.wallet"
        :component-type="state"
        @chooser="setState"
        @equipped="afterEquip"
      ></ComponentsChooser>
    </template>
  </Modal>
</template>

<script setup>
import { computed, onMounted, onUnmounted, ref, watch } from "vue";
import Modal from "@/components/Modal.vue";
import { useShipsStore } from "@/stores/ShipsStore";
import { useAtomicAssetsStore } from "@/stores/AtomicAssetsStore";
import bus from "@/eventBus";
import * as bootstrap from "bootstrap/dist/js/bootstrap.esm.min";
import ComponentsIndex from "@/components/modals/ComponentsModal/ComponentsIndex.vue";
import ComponentsChooser from "@/components/modals/ComponentsModal/ComponentsChooser.vue";
import { useAuthStore } from "@/stores/AuthStore";

const shipsStore = useShipsStore();
const atomicAssetsStore = useAtomicAssetsStore();
const authStore = useAuthStore();

let componentsModal;

const shipId = ref("");
const state = ref("index");
const ship = ref({});
const equipped = ref([]);

const afterEquip = (asset) => {
  // Update the ships part assets
  ship.value.parts.splice(parseInt(asset.data.tdata), 1, asset.asset_id);

  // Update the ships equipped parts
  const assetIndex = equipped.value.findIndex(e => e.data.tdata === asset.data.tdata);
  if(assetIndex >= 0) {
    equipped.value.splice(assetIndex, 1, asset);
  } else {
    equipped.value.push(asset);
  }

  // console.log({
  //   shipParts: ship.value.parts,
  //   equipped: equipped.value,
  // });

  // Revert to component index
  setState("index");

  // setTimeout(() => {
  //   refreshShipPartsAndAssets(shipId.value);
  // }, 5000);
};

const setState = (newState) => {
  state.value = newState;
};

const afterCloseModal = () => {
  shipsStore.getShip(shipId.value);
  shipId.value = "";
  ship.value = {};
  equipped.value = [];
};

const closeModal = () => {
  componentsModal.hide();
};

const openModal = (ship_id) => {
  // console.log('=== Fetching ship + assets for #'+ship_id+' ===');
  state.value = "index";
  shipId.value = ship_id;
  refreshShipPartsAndAssets(ship_id);
  componentsModal.show();
};

const refreshShipPartsAndAssets = async (ship_id) => {
  await shipsStore.getShip(ship_id, (freshShip) => {
    ship.value = freshShip;
    atomicAssetsStore.fetchAssets(freshShip.parts, (assets) => {
      equipped.value = assets;
    });
  });
}

const modalTitle = computed(() => {
  let string = "Components for Ship ";
  if(shipId.value) {
    string += shipId.value;
  }
  return string;
});

onUnmounted(() => {
  bus.$off('openComponentsModal');
  bus.$off('closeComponentsModal');
});

onMounted(() => {
  if(process.env.NODE_ENV !== "production" && import.meta.env.VITE_SHOW_MOUNTED_DEBUG === "true") {
    console.debug("ComponentsModal mounted");
  }

  state.value = "index";

  bus.$on('openComponentsModal', (ship_id) => {
    openModal(ship_id);
  });

  bus.$on('closeComponentsModal', (args) => {
    closeModal();
  });

  bus.$on('componentUnequipped', (args) => {
    // Remove component ID from ship.parts
    const shipPartIndex = ship.value.parts.findIndex(part => part === args.assetId);
    ship.value.parts[shipPartIndex] = 0;

    // Remove asset from eqquipped
    const assetIndex = equipped.value.findIndex(asset => asset.asset_id === args.assetId);
    equipped.value.splice(assetIndex, 1)
  });



  componentsModal = new bootstrap.Modal(document.getElementById('componentsModal'));

  const componentsModalEl = document.getElementById('componentsModal')
  componentsModalEl.addEventListener('hidden.bs.modal', afterCloseModal);

});
</script>
