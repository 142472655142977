<template>
  <div class="progress">
    <div class="progress-bar overflow-visible" :class="colourClass"  role="progressbar" :style="{width: props.percent+'%'}" :aria-valuenow="props.percent" aria-valuemin="0" aria-valuemax="100">
      <span>{{helpers.round(props.percent, 1)+"%"}}</span>
    </div>
  </div>
</template>

<script setup>
import helpers from "@/helpers/global-helpers";
import { computed } from "vue";

const props = defineProps({
  percent: {
    type: Number,
    default: 0
  }
});

const colourClass = computed(() => {
  let className = 'bg-success';
  if(props.percent >= 90) {
    className = 'bg-danger';
  } else if(props.percent >= 60) {
    className = 'bg-warning';
  }
  return className;
});

</script>

