const roundToTwo = (num) => (+(Math.round(parseFloat(num) + "e+2")  + "e-2")).toLocaleString();

const  capitalizeFirstLetter = (str) => str.charAt(0).toUpperCase() + str.slice(1);

const separateThousands = (number) => parseFloat(number).toLocaleString();

const round = (value, precision= 2) => {
  const multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
}


export default {
  roundToTwo,
  capitalizeFirstLetter,
  separateThousands,
  round,
}