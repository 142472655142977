<template>
    <a class="nav-link dropdown-toggle d-flex align-items-center" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false" id="resourcesDropdown" @click="toggleDropdown">
      <i class="bi bi-cpu-fill me-2 text-white" v-tooltip="'WAX CPU'"></i> <span :class="colourClass">{{Math.round(accountStore.cpu)}}%</span>
    </a>
    <ul class="dropdown-menu dropdown-menu-dark">
      <li><h6 class="dropdown-header">WAX Resources</h6></li>
      <li>
        <table class="table table-borderless table-sm m-0 text-white">
          <tbody>
          <tr>
            <td class="py-0 small">CPU:</td>
            <td width="100%"><ProgressBar :percent="accountStore.cpu" :class="'bg-dark thin square'" /></td>
          </tr>
          <tr>
            <td class="py-0 small">NET:</td>
            <td><ProgressBar :percent="accountStore.net" :class="'bg-dark thin square'" /></td>
          </tr>
          <tr>
            <td class="py-0 small">RAM:</td>
            <td><ProgressBar :percent="accountStore.ram" :class="'bg-dark thin square'" /></td>
          </tr>
          </tbody>
        </table>
      </li>
    </ul>
</template>

<script setup>
import * as bootstrap from "bootstrap/dist/js/bootstrap.bundle.min";
import { useAccountStore } from "@/stores/AccountStore";
import ProgressBar from "./ProgressBar.vue";
import { computed, onMounted, onUnmounted } from "vue";
const accountStore = useAccountStore();

let dropdownElem;
let dropdownInstance;

function toggleDropdown() {
  dropdownInstance = bootstrap.Dropdown.getOrCreateInstance(dropdownElem);
  dropdownInstance.toggle();
}
const colourClass = computed(() => {
  let className = 'text-success';
  if(parseFloat(accountStore.cpu) >= 90) {
    className = "text-danger";
  } else if(parseFloat(accountStore.cpu) >= 60) {
    className = "text-warning";
  }
  return className;
});

onMounted(() => {
  if(process.env.NODE_ENV !== "production" && import.meta.env.VITE_SHOW_MOUNTED_DEBUG === "true") {
    console.debug("WaxResourcesDropdown mounted");
  }

  dropdownElem = document.getElementById('resourcesDropdown');
  dropdownInstance = bootstrap.Dropdown.getOrCreateInstance(dropdownElem);
});
</script>
