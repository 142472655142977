<template>
  <div class="grid-row d-flex">
    <div class="cell cell-name"><b>{{ component.name }}</b></div>
    <div class="cell cell-equipped ms-auto"><button class="btn btn-sm w-100" :class="badgeClass" @click="openComponentChooser">{{ rarityName}}</button></div>
    <div class="cell cell-unequip ms-3"><button class="btn btn-sm btn-danger" :class="{'invisible':!hasEquippedComponent}" @click="emitUnequipComponent"><i class="bi bi-x"></i></button></div>
  </div>
</template>

<script setup>
import { computed, onMounted } from "vue";
import rarities from "@/config/rarities.json"

const props = defineProps({
  component: {
    type: Object,
    default: {}
  },
  asset: {
    type: Object,
    default: {}
  },
});

const emit = defineEmits(["unequip", "chooser"]);

const rarity = computed(() => props.asset?.data?.rdata);
const badgeClass = computed(() => rarity.value ? "btn-"+rarities[rarity.value].class : 'btn-unequipped');
const rarityName = computed(() => rarity.value ? rarities[rarity.value].name : '--');
const hasEquippedComponent = computed(() => (props.asset?.asset_id));

const openComponentChooser = () => {
  emit("chooser", props.component.key);
};

const emitUnequipComponent = () => {
  emit("unequip", props.asset.asset_id);
};

onMounted(() => {
  if(process.env.NODE_ENV !== "production" && import.meta.env.VITE_SHOW_MOUNTED_DEBUG === "true") {
    console.debug(`ShipComponentGridRow mounted`);
  }

});
</script>