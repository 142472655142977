<template>
  <span class="badge " :class="statusClass">
    <i class="bi bi-check" v-if="statusClass === 'bg-success'"></i>
    {{ shipStatusText}}
  </span>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import { useShipsStore } from "@/stores/ShipsStore";
import moment from "moment/moment";
import { useRentalsStore } from "../../stores/RentalsStore";
import { usePlayerStore } from "../../stores/PlayerStore";

const shipsStore = useShipsStore();
const rentalsStore = useRentalsStore();
const playerStore = usePlayerStore();

const props = defineProps({
  ship: {
    type: Object,
    default: {}
  }
});

const shipStatus = computed(() => {
  let arrived = moment.utc().unix() >= props.ship.end;
  return arrived ? (props.ship.planet > 0 ? 'at_planet' : 'at_spacestation') : (props.ship.planet > 0 ? 'to_planet' : 'to_spacestation');
});

const rentalOwner = computed(() => {
  return rentalsStore.isRentalOwned(props.ship.key, playerStore.name);
});

const statusClass = computed(() => {
  if(rentalOwner.value) {
    return 'bg-danger';
  } else if(shipStatusText.value === 'At Planet') {
    return 'bg-danger';
  } else if(shipStatusText.value === 'Sendable') {
    return 'bg-success';
  }  else if(shipStatusText.value === 'At Spacestation') {
    return 'bg-warning';
  }   else if(shipStatusText.value === 'No pre-set') {
    return 'bg-warning';
  }  else if(shipStatusText.value === 'Travelling') {
    return 'bg-warning';
  } else {
    return 'bg-danger';
  }
});

const shipStatusText = computed(() => {
  if(rentalOwner.value) {
    return 'Rented Out';
  } else if(shipStatus.value === 'at_planet') {
    return `At Planet`;
  } else if(shipStatus.value === 'at_spacestation') {
    if( props.ship.planet === 0
      && props.ship.end < moment.utc().unix()
      && props.ship.parts.filter(partId => partId > 0).length >= 12
      && shipsStore.getShipPreset(props.ship.key)) {
      return 'Sendable';
    } else if(!shipsStore.getShipPreset(props.ship.key)) {
      return 'No pre-set';
    } else {
      return 'At Spacestation';
    }
    // return 'Arrived at '+this.spacestation + ' spacestation';
  } else if(shipStatus.value === 'to_planet') {
    return 'Travelling';
  } else if(shipStatus.value === 'to_spacestation') {
    return 'Travelling';
  } else {
    return 'Unknown';
  }
});

onMounted(() => {
  if(process.env.NODE_ENV !== "production" && import.meta.env.VITE_SHOW_MOUNTED_DEBUG === "true") {
    console.debug(`MineableStatus for Ship ${props.ship.key} mounted`);
  }
});
</script>
