import { defineStore } from "pinia";
import { ref } from "vue";
import axios from "axios";
import moment from "moment/moment";
import { useConfigStore } from "@/stores/ConfigStore";

export const usePlanetEnhanceStore = defineStore("planetenhance", () => {
  const configStore = useConfigStore();

  const maxPools = ref([]);
  const enhancements = ref([]);

  function getEnhancements() {
    return enhancements.value.length > 0 ? enhancements.value : fetchEnhancements();
  }

  async function fetchEnhancements() {
    //Empty enhancements
    enhancements.value = [];

    const excludedTemplateIds = [315854, 315860];

    let more = true;
    let next_key = 0;
    let page = 1;

    while(more && page < 10) {
    //   console.info('Fetching 100 configs from lower bound '+next_key+'. Page: '+page);

      const lowerBound = next_key > 0 ? next_key : null;

      await axios.post(configStore.apiNode + "/v1/chain/get_table_rows", {
        json: true,
        code: configStore.contract,
        table: "plntupgconf",
        scope: configStore.contract,
        index_position: 1,
        lower_bound: lowerBound,
        upper_bound: null,
        reverse: false,
        show_payer: false,
        limit: 100,
        key_type: '',
      })
        .then((response) => {
          // console.debug("enhancements ", response.data);

          if(typeof response.data.more !== "undefined") {
            more = response.data.more;
          }

          if(typeof response.data.next_key !== "undefined") {
            next_key = response.data.next_key;
          }

          if(typeof response.data.rows !== "undefined" && response.data.rows.length > 0) {
            response.data.rows.forEach(enhancement => {
              if(!excludedTemplateIds.includes(enhancement.template_id)) {
                enhancements.value.push(enhancement);
              }
            })
          }
        })
        .catch((error) => {
          // $toast.error(error.message);
          console.error(error);
          more = false;
        })
        .finally(() => {
          page++;
        });
    }
  }

  async function fetchPlanetMaxPools() {
    //Empty pools
    maxPools.value = [];

    await axios.post(configStore.apiNode + "/v1/chain/get_table_rows", {
      json: true,
      code: configStore.contract,
      table: "plntpoolconf",
      scope: configStore.contract,
      lower_bound: null,
      upper_bound: null,
      reverse: false,
      show_payer: false,
      limit: 100,
    })
      .then((response) => {
        // console.debug("maxPools ", response.data);
        if(typeof response.data.rows !== "undefined" && response.data.rows.length > 0) {
          maxPools.value = response.data.rows;
        }
        return response.data;
      })
      .catch((error) => {
        // $toast.error(error.message);
        console.error(error);
      });
  }

  return {
    enhancements,
    maxPools,
    getEnhancements,
    fetchEnhancements,
    fetchPlanetMaxPools,
  };
});
