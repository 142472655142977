<template>
  <span class="badge" :class="badgeClass">{{ rarityName}}</span>
</template>

<script setup>
import { computed, onMounted } from "vue";
import rarities from "@/config/rarities.json"

const props = defineProps({
  rarity: {
    type: Number,
    default: 0
  }
});

const badgeClass = computed(() => "bg-"+rarities[props.rarity].class);
const rarityName = computed(() => rarities[props.rarity].name);

onMounted(() => {
  if(process.env.NODE_ENV !== "production" && import.meta.env.VITE_SHOW_MOUNTED_DEBUG === "true") {
    console.debug(`RarityBadge mounted`);
  }

});
</script>