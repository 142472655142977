import { defineStore } from "pinia";
import { ref } from "vue";
import { useToast } from "vue-toast-notification";
import { useConfigStore } from "@/stores/ConfigStore";
import axios from "axios";
import { useAuthStore } from "@/stores/AuthStore";

export const useRewardsStore = defineStore("rewards", () => {

  const $toast = useToast({ position: "top-right" });
  const configStore = useConfigStore();
  const authStore = useAuthStore();

  const rewards = ref([]);

  function resetRewards() {
    rewards.value = [];
  }

  async function resetAndGetRewards() {
    resetRewards();
    setTimeout(await getRewards, 5000);
  }

  async function getRewards() {
    //TODO: Loop for more than 100 rewards
    axios.post(configStore.apiNode + "/v1/chain/get_table_rows", {
      json: true,
      code: configStore.contract,
      table: "rewards",
      scope: configStore.contract,
      lower_bound: authStore.wallet,
      upper_bound: authStore.wallet,
      index_position: "2",
      key_type: "name",
      reverse: false,
      show_player: false,
      limit: 1000
    })
      .then((response) => {
        console.debug("rewards", response.data);
        rewards.value = response.data.rows || [];
      })
      .catch((error) => {
        // $toast.error(error.message);
        console.error(error);
      });
  }

  return {
    rewards,
    getRewards,
    resetRewards,
    resetAndGetRewards
  };
});