import { createRouter, createWebHashHistory } from "vue-router";
import LoginView from "@/views/LoginView.vue";

const router = createRouter({
  history: createWebHashHistory(import.meta.env.BASE_URL),
  routes: [
    // Login screen
    {
      path: '/',
      name: 'login',
      component: LoginView,
    },

    // Authenticated screens
    {
      path: "/spaceport",
      name: "spaceport",
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import("../views/SpaceportView.vue"),
    },
    {
      path: "/planetarium",
      name: "planetarium",
      component: () => import("../views/PlanetariumView.vue"),
    },
    {
      path: "/my-planets",
      name: "my-planets",
      component: () => import("../views/MyPlanetsView.vue"),
    },
    {
      path: "/leaderboards",
      name: "leaderboards",
      component: () => import("../views/LeaderboardsView.vue"),
    },
    {
      path: "/shop",
      name: "shop",
      component: () => import("../views/ShopView.vue"),
    },
  ],
});

export default router;
