import { defineStore } from "pinia";
import { ref } from "vue";
import { useToast } from "vue-toast-notification";
import { useConfigStore } from "@/stores/ConfigStore";
import axios from "axios";
import moment from "moment";

export const useRentalsStore = defineStore("rentals", () => {

  const $toast = useToast({ position: "top-right" });
  const configStore = useConfigStore();

  const rentals = ref([]);

  function reset() {
    rentals.value = [];
  }

  // async function resetAndGetRentals() {
  //   reset();
  //   setTimeout(await getRentals, 5000);
  // }

  async function getAllRentals() {
    axios.post(configStore.apiNode + "/v1/chain/get_table_rows", {
      json: true,
      code: configStore.contract,
      table: "rentalships",
      scope: configStore.contract,
      reverse: false,
      show_player: false,
      limit: 1000
    })
      .then((response) => {
        console.debug("rentals", response.data);
        rentals.value = response.data.rows || [];

        // //TODO: Remove after testing
        // // TESTING MY SHIPS
        // mockMyShipRentals();
      })
      .catch((error) => {
        // $toast.error(error.message);
        console.error(error);
      });
  }

  // function mockMyShipRentals() {
  //   const startsAt = moment.utc().unix() - 60*60*24*10;
  //   const rentalDays = 30;
  //   const endsAt = startsAt + 60*60*24*rentalDays;
  //   // rent my ships
  //   [30900, 30901, 30902].forEach((shipId) => {
  //     rentals.value.push({
  //       key: shipId,
  //       owner_commission: 80,
  //       owner_id: "primate.ssg",
  //       rental_days: rentalDays,
  //       rental_start: startsAt,
  //       rental_end: endsAt,
  //       renter_id: "d24bg.wam"
  //     });
  //   });
  //   //rent some ships to me
  //   [198, 199, 200].forEach((shipId) => {
  //     //Remove from current rentals first
  //     const existsIndex = rentals.value.findIndex(rental => rental.key === shipId);
  //     if(existsIndex > -1) {
  //       rentals.value.splice(existsIndex, 1);
  //     }
  //     rentals.value.push({
  //       key: shipId,
  //       owner_commission: 80,
  //       owner_id: "d24bg.wam",
  //       rental_days: rentalDays,
  //       rental_start: startsAt,
  //       rental_end: endsAt,
  //       renter_id: "primate.ssg"
  //     });
  //   });
  // }

  function isRentalOwned(shipId, player) {
    const now = moment.utc().unix();
    const exists = rentals.value.find(rental => rental.key === parseInt(shipId) && rental.rental_end > now && rental.owner_id === player);
    return !!exists;
  }

  function isRentalRented(shipId, player) {
    const now = moment.utc().unix();
    const exists = rentals.value.find(rental => rental.key === parseInt(shipId) && rental.rental_end > now && rental.renter_id === player);
    return !!exists;
  }

  function rentedToPlayer(player) {
    const now = moment.utc().unix();
    const rented = rentals.value.filter((rental) =>
      rental.rental_end > now
      && rental.renter_id === player
    );
    return rented || [];
  }

  function rentalEnd(shipId) {
    return rentals.value.find((rental) => rental.key === shipId)?.rental_end;
  }

  return {
    rentals,
    getAllRentals,
    reset,
    isRentalOwned,
    isRentalRented,
    rentedToPlayer,
    rentalEnd
  };
});