import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { useAuthStore } from "@/stores/AuthStore";
import { useConfigStore } from "@/stores/ConfigStore";
import axios from "axios";

export const useAccountStore = defineStore("account", () => {

  // const $toast = useToast({ position: "top-right" });
  const authStore = useAuthStore();
  const configStore = useConfigStore();

  const account = ref({});
  const balances = ref([]);

  const kyanite = computed(() => parseFloat(balances.value.find(balance => balance.contract === "kyanitelpnft" && balance.currency === "KYANITE")?.amount || 0));
  const wax = computed(() => parseFloat(balances.value.find(balance => balance.contract === "eosio.token" && balance.currency === "WAX")?.amount || 0));
  const cpu = computed(() => (account?.value?.cpu_limit?.used || 0) / (account?.value?.cpu_limit?.max || 0) *100);
  const net = computed(() => (account?.value?.net_limit?.used || 0) / (account?.value?.net_limit?.max || 0) *100);
  const ram = computed(() => (account?.value?.ram_usage || 0) / (account?.value?.ram_quota || 0) *100);

  function reset() {
    account.value = {};
    balances.value = [];
  }

  async function getAccount() {
    axios.post(configStore.apiNode + "/v1/chain/get_account", {
      account_name: authStore.wallet
    })
      .then((response) => {
        console.debug("account", response.data);
        account.value = response.data || {};
      })
      .catch((error) => {
        // $toast.error(error.message);
        console.error(error);
      });
  }

  async function getBalances() {
    // let url = configStore.apiNode + "/api/balances/wax/"+authStore.wallet
    let url = "https://wax.light-api.net/api/balances/wax/"+authStore.wallet;

    await axios.get(url)
      .then(response => {
        console.debug("balances", response.data);
        if(typeof response.data.balances !== "undefined") {
          balances.value = response.data.balances;
        } else {
          balances.value = [];
        }
      })
      .catch(error => {
          console.error(error);
      });

  }



  return {
    account,
    balances,
    getAccount,
    getBalances,
    reset,
    kyanite,
    wax,
    cpu,
    net,
    ram,
  };
});