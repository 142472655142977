<template>
    <a class="nav-link dropdown-toggle align-items-center p-0" style="margin-top: -5px;margin-bottom: -5px;" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false" id="balancesDropdown" @click="toggleDropdown">
       <span v-tooltip="'Starship Balance'" class="d-inline-block text-blue">SS: {{ helpers.roundToTwo(playerStore.starship) }}</span><br>
       <span v-tooltip="'Kyanite (staked)'" class="d-inline-block text-purple">KY: {{ helpers.roundToTwo(playerStore.kyanite) }}</span>
    </a>
    <ul class="dropdown-menu dropdown-menu-dark" style="width: 220px;">
      <li><h6 class="dropdown-header">Balances</h6></li>
      <li>
        <BalancesTable />
      </li>
    </ul>
</template>

<script setup>
import * as bootstrap from "bootstrap/dist/js/bootstrap.bundle.min";
import { usePlayerStore } from "@/stores/PlayerStore";
import { onMounted } from "vue";
import helpers from "@/helpers/global-helpers";
import BalancesTable from "@/components/BalancesTable.vue";

const playerStore = usePlayerStore();
let dropdownElem;
let dropdownInstance;

function toggleDropdown() {
  dropdownInstance = bootstrap.Dropdown.getOrCreateInstance(dropdownElem);
  dropdownInstance.toggle();
}

onMounted(() => {
  if(process.env.NODE_ENV !== "production" && import.meta.env.VITE_SHOW_MOUNTED_DEBUG === "true") {
    console.debug("BalancesDropdown mounted");
  }

  dropdownElem = document.getElementById('balancesDropdown');
  dropdownInstance = bootstrap.Dropdown.getOrCreateInstance(dropdownElem);
});
</script>
