import { defineStore } from "pinia";
import { ref } from "vue";

export const useConfigStore = defineStore("config", () => {
  const rpcNode = ref("https://wax.greymass.com");
  const apiNode = ref("https://api.waxsweden.org");
  const appID = ref("pirateship"); //used in sessionStorage prefixes and anchor init + restore calls
  const theme = ref(""); //will eventually be able to changes themes based on spacestation or genesis editions!
  const chainId = ref("1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4");
  const contract = ref("starshipgame");

  function setRpcNode(endpoint) {
    rpcNode.value = endpoint;
    window.localStorage.setItem(`${appID.value}-rpcnode`, endpoint);
  }

  function setApiNode(endpoint) {
    apiNode.value = endpoint;
    window.localStorage.setItem(`${appID.value}-apinode`, endpoint);
  }

  function setTheme(themeSlug) {
    theme.value = themeSlug;
    window.localStorage.setItem(`${appID.value}-theme`, themeSlug);
    setThemeDataAttribute(themeSlug);
  }

  function setThemeDataAttribute(theme) {
    const bodyElem = document.querySelector("body");
    bodyElem.dataset.theme = theme;
  }

  function initFromStorage(wallet) {
    // Get persistent stuff from local storage
    const storedRpcNode = window.localStorage.getItem(`${appID.value}-rpcnode`);
    if (storedRpcNode) {
      rpcNode.value = storedRpcNode;
    }
    const storedApiNode = window.localStorage.getItem(`${appID.value}-apinode`);
    if (storedApiNode) {
      apiNode.value = storedApiNode;
    }
    // const storedTheme = window.localStorage.getItem(`${appID.value}-theme`);
    // if (storedTheme) {
    //   theme.value = storedTheme;
    //   setThemeDataAttribute(storedTheme);
    // } else {
    //   setThemeDataAttribute("");
    // }
    setThemeDataAttribute(""); //Setting the default until we release more themes

    if (wallet) {
      const storedConfig = window.localStorage.getItem(`${appID.value}-${wallet}`);
      if (storedConfig) {
        // console.log(storedConfig);
        //TODO

        //override nodes
        if (storedRpcNode) {
          rpcNode.value = storedRpcNode;
        }
        if (storedApiNode) {
          apiNode.value = storedApiNode;
        }
      }
    }
  }

  return {
    rpcNode,
    apiNode,
    appID,
    theme,
    chainId,
    contract,
    setRpcNode,
    setApiNode,
    setTheme,
    initFromStorage
  };
});