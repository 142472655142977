<template>
  <p class="help">Click on a component slot to replace that component.</p>

  <div class="grid component-grid" style="max-width: 350px;">
    <ShipComponentGridRow
      v-for="component in componentsJSON"
      :key="component.key"
      :component="component"
      :asset="equippedComponents.find(asset => asset.data.tdata === component.key.toString())"
      @unequip="unequipComponent"
      @chooser="componentChooser"
    />
  </div>

  <div v-if="equippedComponents.length > 0" class="d-flex mt-4">
    <button class="btn w-100 btn-outline-secondary ms-auto" @click="unequipAllComponents">{{ unequipBtnText}}</button>
  </div>

<!--  <code class="w-100">TODO: List stats - like on tools</code><br>-->
</template>

<script setup>

import { computed, onMounted } from "vue";
import componentsJSON from "@/config/components.json";
import ShipComponentGridRow from "@/components/ship/ShipComponentGridRow.vue";
import { usePlayerActionsStore } from "@/stores/actions/PlayerActionsStore";

const playerActionsStore = usePlayerActionsStore();

const props = defineProps({
  ship: {
    type: Object,
    default: {}
  },
  equippedComponents: {
    type: Array,
    default: []
  },
});

const emit = defineEmits(["chooser"]);

const unequipBtnText = computed(() => {
  return props.equippedComponents.length > 1
    ? `Unequip ${props.equippedComponents.length} Components`
    : `Unequip ${props.equippedComponents.length} Component`;
});

const unequipComponent = (assetId) => {
  playerActionsStore.unequipComponents(props.ship.key, [assetId]);
};

const unequipAllComponents = () => {
  const componentsToUnequip = props.equippedComponents.map(a => a.asset_id);
  playerActionsStore.unequipComponents(props.ship.key, componentsToUnequip);
};

const componentChooser = (typeId) => {
  emit("chooser", typeId);
};

onMounted(() => {
  if(process.env.NODE_ENV !== "production" && import.meta.env.VITE_SHOW_MOUNTED_DEBUG === "true") {
    console.debug("ComponentsIndex mounted");
  }
});
</script>