import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { useToast } from "vue-toast-notification";
import { useAuthStore } from "@/stores/AuthStore";
import { useConfigStore } from "@/stores/ConfigStore";
import { useRewardsStore } from "@/stores/RewardsStore";
import axios from "axios";
import { useShipsStore } from "@/stores/ShipsStore";

export const usePlayerStore = defineStore("player", () => {

  const $toast = useToast({ position: "top-right" });
  const authStore = useAuthStore();
  const configStore = useConfigStore();
  const shipsStore = useShipsStore();
  const rewardsStore = useRewardsStore();

  const player = ref({});
  const lastClaim = ref(0);

  const name = computed(() => player.value.player || null);
  const starship = computed(() => parseFloat(player.value.starship_balance || null));
  const kyanite = computed(() => parseFloat(player.value.kyanite || null));

  function resetPlayer() {
    player.value = {};
  }

  function reset() {
    // resetPlayer(); //this causes the player to be emptied causing  a getShips call to return 100 ships!!!
    shipsStore.resetShips();
    rewardsStore.resetRewards();
    shipsStore.resetPresets();
  }

  async function getPlayer() {
    axios.post(configStore.apiNode + "/v1/chain/get_table_rows", {
      json: true,
      code: configStore.contract,
      table: 'players',
      scope: configStore.contract,
      lower_bound: authStore.wallet,
      index_position: 'primary',
      key_type: 'name',
      reverse: false,
      show_payer: false,
      limit: 1
    })
      .then((response) => {
        console.debug("player", response.data);
        player.value = response.data.rows[0] || {};
        if(typeof response.data.rows[0] !== "undefined" && typeof response.data.rows[0].last_claim !== "undefined") {
          lastClaim.value = response.data.rows[0].last_claim || 0;
        }
        setTimeout(() => {
          shipsStore.getShips();
        }, 300);

      })
      .catch((error) => {
        // $toast.error(error.message);
        console.error(error);
      });
  }

  return {
    player,
    lastClaim,
    getPlayer,
    reset,
    name,
    starship,
    kyanite
  };
});