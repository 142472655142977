import { createApp } from "vue";
import { createPinia } from "pinia";
import ToastPlugin from 'vue-toast-notification';
import App from "./App.vue";
import router from "./router";
import { useAuthStore } from "@/stores/AuthStore";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import FloatingVue from 'floating-vue'

import "bootstrap/dist/js/bootstrap.bundle.min"
import 'vue-toast-notification/dist/theme-sugar.css';
import 'floating-vue/dist/style.css'
import '@/sass/main.scss';

const app = createApp(App);

if(process.env.NODE_ENV === "production") {
  Sentry.init({
    app,
    dsn: "https://475fa5d341704d8cbe88971bac523b8c@o4504692694777856.ingest.sentry.io/4504692698710016",
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: ["pirateship-beta.primatepirate.com", /^\//],
      }),
    ],
    logErrors: true,
    environment: process.env.NODE_ENV,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}


// BUGFIX: This line is to fix the "f is not defined" error when trying to send waxjs transactions
// Ref: https://github.com/vitejs/vite/issues/2618#issuecomment-820919951
window.global = window;

app.use(ToastPlugin,{
  position: 'top-right'
});

// // Make sure to apply all app configurations before mounting the app!
// app.config.errorHandler = (err) => {
//   /* handle error */
//   console.error(err);
// }

app.use(FloatingVue)

app.use(createPinia());

// Init the auth store, so we can init autologins and wallet instances prior to booting the app
const authStore = useAuthStore();
authStore.init();

app.use(router);
app.mount("#app");
