<template>
  <div class="card component" @click="emitEquipComponent">
    <div>
      <img :src="`/images/nfts/${asset.template.template_id}.png`" class="img-fluid" :alt="`${asset.data.rarity} ${asset.data.name}`">
    </div>
    <span class="help text-white bg-black">{{asset.asset_id}}</span>
    <RarityBadge :rarity="parseInt(asset.data.rdata)"></RarityBadge>
  </div>
</template>

<script setup>
import { computed, onMounted } from "vue";
import rarities from "@/config/rarities.json"
import RarityBadge from "@/components/ship/RarityBadge.vue";

const props = defineProps({
  asset: {
    type: Object,
    default: {}
  },
});

const emit = defineEmits(["equip"]);

const emitEquipComponent = () => {
  emit("equip", props.asset.asset_id);
};

onMounted(() => {
  if(process.env.NODE_ENV !== "production" && import.meta.env.VITE_SHOW_MOUNTED_DEBUG === "true") {
    console.debug(`ComponentChooserComponent mounted`);
  }

});
</script>