<template>
  <div class="text-center">
    <span class="info-bar">
      <b>RPC:</b> {{ rpc }} | <b>API:</b> {{ api }}
    </span>
  </div>
</template>

<script setup>
import { useConfigStore } from "@/stores/ConfigStore";
import { computed, onMounted } from "vue";

const configStore = useConfigStore();

const rpc = computed(() => configStore.rpcNode.replace("https://", ""));
const api = computed(() => configStore.apiNode.replace("https://", ""));

onMounted(() => {
  if(process.env.NODE_ENV !== "production" && import.meta.env.VITE_SHOW_MOUNTED_DEBUG === "true") {
    console.debug("InfoBar mounted");
  }

});
</script>
