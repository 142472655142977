import { ref, computed } from "vue";
import { defineStore } from "pinia";
import * as waxjs from "@waxio/waxjs/dist";
import router from "@/router";
import { useToast } from "vue-toast-notification";
import AnchorLinkBrowserTransport from "anchor-link-browser-transport";
import AnchorLink from "anchor-link";
import { useConfigStore } from "@/stores/ConfigStore";
import { usePlayerStore } from "@/stores/PlayerStore";
import { useShipsStore } from "@/stores/ShipsStore";
import { useSpacestationsStore } from "@/stores/SpacestationsStore";
import axios from "axios";
import { useRewardsStore } from "@/stores/RewardsStore";
import {useWhitelist} from "@/config/whitelist";
import { usePlanetsStore } from "./PlanetsStore";
import { useRentalsStore } from "./RentalsStore";
import { useAccountStore } from "./AccountStore";

export const useAuthStore = defineStore("auth", () => {

  const $toast = useToast({ position: "top-right" });

  const configStore = useConfigStore();
  const playerStore = usePlayerStore();
  const spacestationsStore = useSpacestationsStore();
  const rewardsStore = useRewardsStore();
  const shipsStore = useShipsStore();
  const planetsStore = usePlanetsStore();
  const rentalsStore = useRentalsStore();
  const accountStore = useAccountStore();
  const whitelist = useWhitelist;

  const transport = new AnchorLinkBrowserTransport({
    classPrefix: "anchor-link",
    injectStyles: true,
    requestStatus: true,
    storagePrefix: "anchor-link",
    disableGreymassFuel: true
  });

  const anchorLink = new AnchorLink({
    transport,
    chains: [
      {
        chainId: configStore.chainId,
        nodeUrl: configStore.rpcNode
      }
    ]
  });

  // The logged in account wallet
  const wallet = ref("");
  // The last  logged in time from starship tables (so we can trigger a logout or remind to log in before then)
  const lastLoggedIn = ref("");
  // The authenticator type (wax|anchor|null)
  const authenticator = ref("");
  // const genesisNFTs = ref([]);
  // const accessPassNFTs = ref([]);

  // wallet instances
  const wallets = {};

  //Getters (computed())
  const isLoggedIn = computed(() => (wallet.value !== null && wallet.value !== "" && wallet.value !== false));

  // Actions
  async function loginWax() {
    try {
      const userAccount = await wallets.wax.login();
      if (userAccount) {
        // save user account to cookie or local storage/session (for 24 hours)
        sessionStorage.setItem(`${configStore.appID}-account`, userAccount);
        sessionStorage.setItem(`${configStore.appID}-authenticator`, "wax");
        if (wallets.wax.user) {
          sessionStorage.setItem(`${configStore.appID}-wax-user`, JSON.stringify(wallets.wax.user));
        }

        authenticator.value = "wax";
        wallet.value = userAccount;

        afterLogin();
      }
    } catch (error) {
      console.error(error.message);
      $toast.error(error.message);
    }
  }

  async function loginAnchor() {

    try {
      const identity = await anchorLink.login(configStore.appID);

      const { session } = identity;
      wallets.anchor = session;
      const userAccount = session.auth.actor;

      if (userAccount) {
        // save user account to cookie or local storage/session (for 24 hours?)
        sessionStorage.setItem(`${configStore.appID}-account`, userAccount);
        sessionStorage.setItem(`${configStore.appID}-authenticator`, "anchor");
        sessionStorage.setItem(`${configStore.appID}-anchor-user`, JSON.stringify({ account: userAccount }));

        authenticator.value = "anchor";
        wallet.value = userAccount.toString();

        afterLogin()
      }
    } catch (error) {
      console.warn(error.message);
    }
  }

  /**
   * Check if the wallet has a PIRATESHIP Access Pass from primatesnfts collection
   * @param walletAddress
   * @param callback
   * @returns {Promise<void>}
   */
  async function checkAccessPass(walletAddress, callback) {
    // console.log("Checking wallet "+walletAddress+" for GENESIS");
    // let url = 'https://wax.api.atomicassets.io/atomicassets/v1/assets?collection_name=primatesnfts&schema_name=passes&template_id=679616&owner='+walletAddress
    let url = 'https://aa-api-wax-mainnet-1.eu.aws.pink.gg/atomicassets/v1/assets?collection_name=primatesnfts&schema_name=passes&template_id=679616&owner='+walletAddress

      await axios.get(url)
        .then(response => {
          console.debug("ACCESS PASS", response.data);
          const hasAccessPass = (typeof response.data.data !== 'undefined' && response.data.data.length > 0);
          if(callback && typeof callback === "function") {
            return callback(hasAccessPass);
          }

        })
        .catch(error => {
          if(error.message.includes('429')) {
            console.error('CORS Error - try reloading after a few seconds');
          } else {
            console.error(error.message);
          }
        });
  }


  async function afterLogin(autoLogin=false) {
    //bugfix: might not be needed anymore since we patched in bootstrap js
    document.querySelector("body").style.overflow = null;

    let allowed = false;

    // Check Access Pass first, and then whitelist
    // if(import.meta.env.VITE_ALLOW_ACCESS_PASS_ACCESS === "true") {
      await checkAccessPass(wallet.value, (hasAccessPass) => {
        if(hasAccessPass) {
          console.log("Has ACCESS PASS!");
          allowed = true;
        }
      }).then(() => {
          console.log("after checkAccessPass");
      });
    // }

    //whitelist overrides access pass requirements
    // if(import.meta.env.VITE_ALLOW_WHITELIST_ACCESS === "true") {
      if(whitelist.includes(wallet.value)) {
        console.log("User whitelisted!");
        allowed = true;
      }
    // }

    console.log("allow check now");
    if(!allowed) {
      if(import.meta.env.VITE_ALLOW_ACCESS_PASS_ACCESS === "true") {
        $toast.error("You do not own a PIRATESHIP Access Pass NFT in your wallet, required to login!");
      } else {
        $toast.error("You are not whitelisted to login!");
      }
      return logout();
    }


    backendLogin(wallet.value);
    router.push("/spaceport");
    // if(!autoLogin) {
    //   $toast.success("Login successful");
    // }
    playerStore.reset(); // reset the players + ships + rewards
    rentalsStore.getAllRentals();
    shipsStore.initFromStorage();
    planetsStore.initFromStorage();
    accountStore.getAccount();
    accountStore.getBalances();

    // Nesting some setTimeouts to help reduce CORS errors due to too many simultaneous requests
    // TODO: find more elegant solution to queue multiple requests (with delays between queued calls)
    await playerStore.getPlayer().then((r) => {
      setTimeout(() => {
        spacestationsStore.getSpacestations().then((r) => {
          setTimeout(() => {
            rewardsStore.getRewards();
          }, 300);
        });
      }, 400);
    });

  }



  function logout() {

    sessionStorage.removeItem(`${configStore.appID}-account`);
    sessionStorage.removeItem(`${configStore.appID}-authenticator`);
    sessionStorage.removeItem(`${configStore.appID}-wax-user`);
    sessionStorage.removeItem(`${configStore.appID}-anchor-user`);
    sessionStorage.removeItem(`${configStore.appID}-session`);
    sessionStorage.removeItem(`${configStore.appID}-store-account`);
    document.querySelector("body").style.overflow = null;

    if (authenticator.value === "wax") {
      //Try to reset the wax instance
      wallets.wax = new waxjs.WaxJS({
        rpcEndpoint: configStore.rpcNode, //TODO: Anchor: https://api.waxsweden.org
        tryAutoLogin: false
      });
    }

    shipsStore.resetShips();
    wallet.value = "";
    lastLoggedIn.value = "";
    authenticator.value = "";

    // Redirect to log in screen
    router.push("/");
  }

  function backendLogin(wallet) {
    axios.put(import.meta.env.VITE_BACKEND_URL + "/api/v1/accounts", {
      waxaccount: wallet
    })
      .then(() => {
        lastLoggedIn.value = new Date();
      })
      .catch((error) => {
        // TODO: I think this error comes from somewhere in the resposnse message rather than e.message
        console.error(error);

        if(typeof error.response === "undefined") {
          console.debug("error.response = undefined");
          console.debug(error);
          return false;
        }
        if(typeof error.response.data === "undefined") {
          console.debug("error.response.data = undefined");
          console.debug(error.response);
          return false;
        }
        if(typeof error.response.data.message === "undefined") {
          console.debug("error.response.data.message = undefined");
          console.debug(error.response.data);
          return false;
        }
        if(typeof error.response.data.message.details === "undefined") {
          console.debug("error.response.data.message.details = undefined");
          console.debug(error.response.data.message);
          return false;
        }
        if(typeof error.response.data.message.details[0] === "undefined") {
          console.debug("error.response.data.message.details[0] = undefined");
          console.debug(error.response.data.message.details);
          return false;
        }
        if(typeof error.response.data.message.details[0].message === "undefined") {
          console.debug("error.response.data.message.details[0].message = undefined");
          console.debug(error.response.data.message.details[0]);
          return false;
        }

        // $toast.error(error.response.data.message.details[0].message || error.message);
      });
  }

  async function transact(actions) {
    const transactionOptions = {
      blocksBehind: 3,
      expireSeconds: 1200
    };
    const transactionData = {
      actions: actions
    }

    console.log("transactionData", transactionData);

    try {
      setTimeout(accountStore.getAccount, 2000);
      if (authenticator.value === "anchor") {
        return await wallets.anchor.transact(transactionData);
      } else if (authenticator.value === "wax") {
        return await wallets.wax.api.transact(transactionData, transactionOptions);
      }
    } catch (error) {
      // Make the message nicer
      error.message = error.message
        .replace("assertion failure with message: ", "");
      return Promise.reject(error)
    }
  }

  function init() {
    // console.debug('AuthStore init');
    // check local sessionStorage for user (saves until the current tab/session is closed) TODO: do we still need this?
    const sessionUser = sessionStorage.getItem(`${configStore.appID}-account`);
    const sessionAuth = sessionStorage.getItem(`${configStore.appID}-authenticator`);
    if (sessionUser !== null) {
      wallet.value = sessionUser;
      if (sessionAuth !== null) {
        authenticator.value = sessionAuth;
      }
    }
    configStore.initFromStorage(sessionUser || null);

    // Create the new guest or auto-logged in wax account
    const waxUserSession = JSON.parse(window.sessionStorage.getItem(`${configStore.appID}-wax-user`));
    if (waxUserSession && waxUserSession.account && waxUserSession.keys) {
      wallets.wax = new waxjs.WaxJS({
        rpcEndpoint: configStore.rpcNode,
        userAccount: waxUserSession.account,
        pubKeys: waxUserSession.keys,
        tryAutoLogin: false
      });
      afterLogin(true);
    } else {
      wallets.wax = new waxjs.WaxJS({
        rpcEndpoint: configStore.rpcNode,
        tryAutoLogin: false
      });
    }

    // Restore anchor session
    const anchorUserSession = JSON.parse(window.sessionStorage.getItem(`${configStore.appID}-anchor-user`));
    if (anchorUserSession && anchorUserSession.account) {
      anchorLink.restoreSession(configStore.appID)
        .then((session) => {
          console.info(`Session for ${session.auth} restored`);
          wallets.anchor = session;
          afterLogin(true);
        });
    }
  }

  return {
    wallet,
    lastLoggedIn,
    authenticator,
    isLoggedIn,
    transact,
    loginWax,
    loginAnchor,
    logout,
    init
  }

});
