import { defineStore } from "pinia";
import { ref } from "vue";
import { useToast } from "vue-toast-notification";
import { useConfigStore } from "@/stores/ConfigStore";
import axios from "axios";
import useSpacestationsJSON from "@/config/spacestations.json";

export const useSpacestationsStore = defineStore("spacestations", () => {

  const $toast = useToast({ position: "top-right" });
  const configStore = useConfigStore();
  const spacestationsJSON = useSpacestationsJSON;

  const spacestations = ref([]);

  async function getSpacestations() {
    axios.post(configStore.apiNode + "/v1/chain/get_table_rows", {
      json: true,
      code: configStore.contract,
      table: "spacestation",
      scope: configStore.contract,
      index_position: "primary",
      reverse: false,
      show_payer: false,
      limit: 100
    })
      .then((response) => {
        console.debug("spacestations", response.data);
        spacestations.value = response.data.rows || [];
      })
      .catch((error) => {
        // $toast.error(error.message);
        console.error(error);
      });
  }

  function spacestation(key) {
    // console.log("key", key);
    return spacestationsJSON.find(item => item.key === parseInt(key)) || null;
  }

  function getName(key) {
    // console.log("key", key);
    return spacestationsJSON.find(item => item.key === parseInt(key))?.name || "Unknown";
  }

  function getIcon(key) {
    // console.log("key", key);
    return spacestationsJSON.find(item => item.key === parseInt(key))?.icon || "";
  }

  return {
    spacestations,
    spacestation,
    getSpacestations,
    getName,
    getIcon
  };
});