<template>
  <div class="text-center spacestation-tile">
    <div class="ss-bounding-box mx-auto" :style="`background-image: url(/images/spacestations/wire-icons/${spacestation.icon})`"></div>
    <p class="ss-name">{{spacestation.name}}</p>
  </div>
</template>

<script setup>
import { onMounted } from "vue";

const props = defineProps({
  spacestation: {
    type: Object,
    default: {}
  }
});

onMounted(() => {
  if(process.env.NODE_ENV !== "production" && import.meta.env.VITE_SHOW_MOUNTED_DEBUG === "true") {
    console.debug(`SpacestationTile mounted`);
  }

});
</script>