<template>
  <Modal
    id="sendAllModal"
    title="Send All"
    :on-close="closeModal"
    class=""
    size-class="modal-lg modal-fullscreen-lg-down"
    :use-footer="false"
  >
    <template #body>

      <p class="text-muted">Check the status of all available ships and then click the big <b>Send All</b> button to command them all at once.</p>

      <table class="table" v-if="playerShips.length > 0">
        <thead>
        <tr>
          <th>Ship</th>
          <th>Planet</th>
          <th>Pool</th>
          <th>Status</th>
        </tr>
        </thead>
        <tbody>
          <tr v-for="ship in playerShips" :key="ship.key">
            <td>{{ ship.key}}</td>
            <td>{{ shipsStore.getShipPresetName(ship.key)}}</td>
            <td><span v-if="sendableShips.find(s => s.key === ship.key)">{{ planetsStore.planetPoolDisplay(shipsStore.getShipPreset(ship.key))}}</span></td>
            <td><SendableStatus :ship="ship" /></td>
          </tr>
        </tbody>
      </table>

      <div class="text-center">
        <div class="bullet-button-wrapper m-2">
          <button class="btn btn-xl w-100 btn-special" :class="{'firing':sendAllCount > 1}" @click="createSendAllTransaction" :disabled="sendAllCount === 0">
            Send All <small class="text-normal">({{ sendAllCount }})</small>
            <div class="bullet aqua"></div>
            <div class="bullet hotpink"></div>
            <div class="bullet yellow"></div>
          </button>
        </div>
      </div>

    </template>

  </Modal>
</template>

<script setup>
import { computed, onMounted, onUnmounted, ref } from "vue";
import Modal from "@/components/Modal.vue";
import { useConfigStore } from "@/stores/ConfigStore";
import { useAuthStore } from "@/stores/AuthStore";
import { useShipsStore } from "@/stores/ShipsStore";
import { usePlanetsStore } from "@/stores/PlanetsStore";
import { usePlayerActionsStore } from "@/stores/actions/PlayerActionsStore";
import bus from "@/eventBus";
import * as bootstrap from "bootstrap/dist/js/bootstrap.esm.min";
import moment from "moment/moment";
import SendableStatus from "../ship/SendableStatus.vue";
import { useRentalsStore } from "../../stores/RentalsStore";

const authStore = useAuthStore();
const configStore = useConfigStore();
const shipsStore = useShipsStore();
const planetsStore = usePlanetsStore();
const playerActionsStore = usePlayerActionsStore();
const rentalsStore = useRentalsStore();


let thisModal;

const closeModal = () => {
  bus.$emit("closeAll");
  thisModal.hide();
};

const openModal = () => {
  fetchPlanets();
  thisModal.show();
};

const fetchPlanets = () => {
  if(sendableShips.value.length > 0) {
    // get list of sendable planets
    let planetIds = [];
    sendableShips.value.forEach((ship) => {
      const planetId = shipsStore.getShipPreset(ship.key);
      if(planetId) {
        planetIds.push(planetId);
      }
    });
    // deduplicate list
    planetIds = [...new Set(planetIds)];
    // Send to planetsStore
    if(planetIds.length > 0) {
      planetsStore.getPlanets(planetIds);
    }
  }
};

const playerShips = computed(() => shipsStore.ships || []);

const sendAllCount = computed(() => sendableShips.value.length);

const sendableShips = computed(() => {
  return playerShips.value.filter((ship) => {
    return ship.planet === 0
      && ship.end < moment.utc().unix()
      && ship.parts.filter(partId => partId > 0).length >= 12
      && shipsStore.getShipPreset(ship.key)
      && !rentalsStore.isRentalOwned(ship.key, authStore.wallet);
    //TODO: Also check that the planet is at the same spacestation as the ship
  })
});

function createSendAllTransaction() {
  console.log("createSendAllTransaction()");
  // filter only sendable ships before sending
  playerActionsStore.sendAll(sendableShips.value);
}

onUnmounted(() => {
  bus.$off('openSendAllModal');
  bus.$off('closeSendAllModal');
});

onMounted(() => {
  if(process.env.NODE_ENV !== "production" && import.meta.env.VITE_SHOW_MOUNTED_DEBUG === "true") {
    console.debug("SandAllModal mounted");
  }

  bus.$on('openSendAllModal', () => {
    console.log('bus.$on.openSendAllModal');
    openModal();
  });

  bus.$on('closeSendAllModal', (args) => {
    console.log('bus.$on.closeSendAllModal', args);
    closeModal();
  });

  thisModal = new bootstrap.Modal(document.getElementById('sendAllModal'));

  // const mineAllModalElem = document.getElementById('mineAllModal')
  // mineAllModalElem.addEventListener('hidden.bs.modal', () => {
  //   //
  // });
});
</script>
