<template>
  <Modal
    id="spacestationsModal"
    :title="modalTitle"
    :on-close="closeModal"
    class=""
    size-class="modal-xl modal-fullscreen-lg-down"
    :use-footer="false"
  >
    <template #body>

      <div class="row">
        <div class="col-4 col-sm-3 col-md-2 col-lg-2 col-xl-2 mb-3" v-for="spacestation in spacestationsJSON" :key="spacestation.key">
          <div class="h-100 spacestation-tile-selector" :class="{'active': spacestationID === parseInt(spacestation.key)}" @click="selectSpacestation(spacestation.key)">
           <SpacestationTile :spacestation="spacestation" />
          </div>
        </div>
      </div>

    </template>

  </Modal>
</template>

<script setup>
import { computed, onMounted, onUnmounted, ref, watch } from "vue";
import Modal from "@/components/Modal.vue";
import useSpacestationsJSON from "@/config/spacestations.json";
import { usePlayerActionsStore } from "@/stores/actions/PlayerActionsStore";
import { useShipsStore } from "@/stores/ShipsStore";
import { useConfigStore } from "@/stores/ConfigStore";
import { useAuthStore } from "@/stores/AuthStore";
import bus from "@/eventBus";
import * as bootstrap from "bootstrap/dist/js/bootstrap.esm.min";
import SpacestationTile from "@/components/spacestations/SpacestationTile.vue";

const authStore = useAuthStore();
const configStore = useConfigStore();
const playerActionsStore = usePlayerActionsStore();
const shipsStore = useShipsStore();
const spacestationsJSON = useSpacestationsJSON;

let ssModal;

const shipId = ref("");

const closeModal = () => {
  ssModal.hide();
};

const openModal = () => {
  ssModal.show();
};

const spacestationID = computed(() => {
  const ship = shipsStore.ship(shipId.value);
  return ship.spacestation || null;
});

const modalTitle = computed(() => {
  let string = "Spacestation Select";
  if(shipId.value) {
    string += ` for Ship ${shipId.value}`;
  }
  return string;
});

const selectSpacestation = (spacestation) => {
  if(spacestationID.value !== parseInt(spacestation)) {
    playerActionsStore.changeSpacestation(shipId.value, spacestation);
  }
};

onUnmounted(() => {
  bus.$off('openSpacestationsModal');
  bus.$off('closeSpacestationsModal');
});

onMounted(() => {
  if(process.env.NODE_ENV !== "production" && import.meta.env.VITE_SHOW_MOUNTED_DEBUG === "true") {
    console.debug("SpacestationsModal mounted");
  }

  bus.$on('openSpacestationsModal', (ship) => {
    console.log('bus.$on.openSpacestationsModal', ship);
    shipId.value = ship;
    openModal();
  });

  bus.$on('closeSpacestationsModal', (args) => {
    console.log('bus.$on.closeSpacestationsModal', args);
    closeModal();
  });

  ssModal = new bootstrap.Modal(document.getElementById('spacestationsModal'));

  const ssModalEl = document.getElementById('spacestationsModal')
  ssModalEl.addEventListener('hidden.bs.modal', () => {shipId.value = ""});

});
</script>
