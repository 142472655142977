import { defineStore } from "pinia";
import { useToast } from "vue-toast-notification";
import { useAuthStore } from "@/stores/AuthStore";
import { useConfigStore } from "@/stores/ConfigStore";
import { useRewardsStore } from "@/stores/RewardsStore";
import { usePlayerStore } from "@/stores/PlayerStore";
import { useShipsStore } from "@/stores/ShipsStore";
import { usePlanetsStore } from "@/stores/PlanetsStore";
import { useSpacestationsStore } from "@/stores/SpacestationsStore";
import bus from "@/eventBus";
import moment from "moment";

export const usePlayerActionsStore = defineStore("player-actions", () => {

  const $toast = useToast({ position: "top-right" });
  const authStore = useAuthStore();
  const configStore = useConfigStore();
  const rewardsStore = useRewardsStore();
  const playerStore = usePlayerStore();
  const shipsStore = useShipsStore();
  const spacestationsStore = useSpacestationsStore();
  const planetsStore = usePlanetsStore();

  const claimRewards = async () => {
    await authStore.transact([{
      account: configStore.contract,
      name: "claimrewards",
      authorization: [{
        actor: authStore.wallet,
        permission: "active"
      }],
      data: {
        player_id: authStore.wallet
      }
    }])
      .then(() => {
        if(authStore.authenticator === "wax") {
          $toast.success("Rewards Claimed!");
        }
        setTimeout(rewardsStore.resetAndGetRewards, 3000);
      })
      .catch(error => {
        $toast.error(error.message)
      });
  };

  const claimKyanite = async () => {
    await authStore.transact([{
      account: configStore.contract,
      name: "claim",
      authorization: [{
        actor: authStore.wallet,
        permission: "active"
      }],
      data: {
        player: authStore.wallet
      }
    }])
      .then((r) => {
        if(authStore.authenticator === "wax") {
          $toast.success("Staking Reward Claimed!");
        }
        setTimeout(playerStore.getPlayer, 3000);
      })
      .catch(error => {
        $toast.error(error.message)
      });
  };



  const minePlanet = async (shipId) => {
    // Get the planetId from the ship BEFORE we start the transaction
    const planetId = shipsStore.ships.find(ship => shipId === ship.key).planet || null;

    await authStore.transact([{
      account: configStore.contract,
      name: "collect",
      authorization: [{
        actor: authStore.wallet,
        permission: "active"
      }],
      data: {
        player: authStore.wallet,
        starship: shipId
      }
    }])
      .then((r) => {
        if(authStore.authenticator === "wax") {
          $toast.success("Planet mined! Returning to space station.");
        }
        shipsStore.manuallyUpdateReturningShip(planetId, shipId, (shipId) => {
          setTimeout(() => {
            shipsStore.getShip(shipId);
          }, 5000);
        });
      })
      .catch(error => {
        $toast.error(error.message)
      });
  };


  const mineAll = async (ships) => {
    if(ships.length < 1) {
      return false;
    }

    const actions = [];
    ships.forEach((ship) => {
        // prepare the transaction action
        actions.push({
          account: configStore.contract,
          name: "collect",
          authorization: [{
            actor: authStore.wallet,
            permission: "active"
          }],
          data: {
            player: authStore.wallet,
            starship: ship.key
          }
        });
    });

    await authStore.transact(actions)
      .then((r) => {
        if(authStore.authenticator === "wax") {
          $toast.success(ships.length+" planets mined! Returning to space station.");
        }
        ships.forEach((ship) => {
          shipsStore.manuallyUpdateReturningShip(ship.planet, ship.key);
        });
        setTimeout(shipsStore.getShips, 10000);
        bus.$emit("closeMineAllModal");
      })
      .catch(error => {
        $toast.error(error.message)
      });
  };

  const sendAll = async (shipsPlanets) => {
    console.log(shipsPlanets);
    if(shipsPlanets.length < 1) {
      return false;
    }

    const actions = [];
    shipsPlanets.forEach((ship, index) => {
      shipsPlanets[index].planet = shipsStore.getShipPreset(ship.key);
        // prepare the transaction action
        actions.push({
          account: configStore.contract,
          name: "move2planet",
          authorization: [{
            actor: authStore.wallet,
            permission: "active"
          }],
          data: {
            player: authStore.wallet,
            starship: ship.key,
            planet: shipsStore.getShipPreset(ship.key)
          }
        });
    });

    await authStore.transact(actions)
      .then((r) => {
        if(authStore.authenticator === "wax") {
          $toast.success(shipsPlanets.length+" ships sent!");
        }
        shipsPlanets.forEach((ship) => {
          shipsStore.manuallyUpdateShipOnMission(ship.key, shipsStore.getShipPreset(ship.key));
        });
        setTimeout(shipsStore.getShips, 10000);
        bus.$emit("closeSendAllModal");
      })
      .catch(error => {
        $toast.error(error.message)
      });
  };

  const unequipComponents = async (shipId, assetIds) => {
    // console.log("unequipComponents", shipId, assetIds);
    if(assetIds.length < 1) {
      return false;
    }

    const actions = [];
    assetIds.forEach((assetId) => {

      // prepare the transaction action
      actions.push({
        account: configStore.contract,
        name: "unattachpart",
        authorization: [{
          actor: authStore.wallet,
          permission: "active"
        }],
        data: {
          player: authStore.wallet,
          starship: shipId,
          part: assetId
        }
      });
    });

    await authStore.transact(actions)
      .then((r) => {
        if(authStore.authenticator === "wax") {
          $toast.success(assetIds.length+" components unequipped!");
        }

        // Remove each of the components from the ships equipped parts
        assetIds.forEach((assetId) => {
          bus.$emit("componentUnequipped", {shipId, assetId});
        });
      })
      .catch(error => {
        $toast.error(error.message)
      });
  };

  const equipComponent = async (shipId, assetId) => {
    // console.log("equipComponent", shipId, assetId);

    const actions = [
      {
        account: 'atomicassets',
        name: 'transfer',
        authorization: [{
          actor: authStore.wallet,
          permission: 'active',
        }],
        data: {
          from: authStore.wallet,
          to: configStore.contract,
          asset_ids: [
            assetId,
          ],
          memo: "Staking Part"
        },
      },{
        account: configStore.contract,
        name: 'attachpart',
        authorization: [{
          actor: authStore.wallet,
          permission: 'active',
        }],
        data: {
          player: authStore.wallet,
          starship: shipId,
          part: assetId
        },
      }
    ];

    await authStore.transact(actions)
      .then((r) => {
        if(authStore.authenticator === "wax") {
          $toast.success("Component equipped!");
        }
//   bus.$emit("componentUnequipped", {shipId, assetId});
      })
      .catch(error => {
        $toast.error(error.message)
      });
  };

  const enhancePlanet = async (shipId, assetId, planetId) => {
    console.debug("enhancePlanet", shipId, assetId, planetId);

    const actions = [
      {
        account: 'atomicassets',
        name: 'transfer',
        authorization: [{
          actor: authStore.wallet,
          permission: 'active',
        }],
        data: {
          from: authStore.wallet,
          to: configStore.contract,
          asset_ids: [
            assetId,
          ],
          memo: "Upgrading Planet"
        },
      },{
        account: configStore.contract,
        name: 'upgradeplnt',
        authorization: [{
          actor: authStore.wallet,
          permission: 'active',
        }],
        data: {
          player: authStore.wallet,
          starship: shipId,
          part: assetId,
          planetid: planetId
        },
      }
    ];

    await authStore.transact(actions)
      .then((r) => {
        if(authStore.authenticator === "wax") {
          $toast.success("Planet Enhanced!");
        }
      })
      .catch(error => {
        $toast.error(error.message)
      });
  };

  const claimPlanet = async (shipId) => {
    // Get the planetId from the ship BEFORE we start the transaction
    const planetId = shipsStore.ships.find(ship => shipId === ship.key).planet || null;

    await authStore.transact([{
      account: configStore.contract,
      name: "claimplanet",
      authorization: [{
        actor: authStore.wallet,
        permission: "active"
      }],
      data: {
        player: authStore.wallet,
        starship: shipId,
        planetid: planetId
      }
    }])
      .then((r) => {
        if(authStore.authenticator === "wax") {
          $toast.success("Planet claimed!");
        }
        planetsStore.manuallyUpdateClaimedPlanet(planetId, shipId);
      })
      .catch(error => {
        $toast.error(error.message)
      });
  };

  const changeSpacestation = async (shipId, spacestationId) => {
    const stationName = spacestationsStore.getName(spacestationId);

    await authStore.transact([{
      account: configStore.contract,
      name: "move2station",
      authorization: [{
        actor: authStore.wallet,
        permission: "active"
      }],
      data: {
        player: authStore.wallet,
        starship: shipId,
        spacestation: spacestationId
      }
    }])
      .then((r) => {
        if(authStore.authenticator === "wax") {
          $toast.success(`Moving to ${stationName} spacestation`);
        }
        bus.$emit('closeSpacestationsModal');
        shipsStore.manuallyUpdateShipChangingStation( shipId, spacestationId);
      })
      .catch(error => {
        bus.$emit('closeSpacestationsModal');
        $toast.error(error.message)
      });
  };

  const sendToPlanet = async (shipId, planetId) => {
    await authStore.transact([{
      account: configStore.contract,
      name: "move2planet",
      authorization: [{
        actor: authStore.wallet,
        permission: "active"
      }],
      data: {
        player: authStore.wallet,
        starship: shipId,
        planet: planetId
      }
    }])
      .then((r) => {
        if(authStore.authenticator === "wax") {
          const planetName = planetsStore.planetName(planetId);
          $toast.success(`Flying to planet ${planetName}`);
        }
        bus.$emit('closeMissionModal');
        shipsStore.manuallyUpdateShipOnMission( shipId, planetId);
      })
      .catch(error => {
        bus.$emit('closeMissionModal');
        $toast.error(error.message)
      });
  };

  const testMultipleTransactions = async () => {
    await authStore.transact([

      {
      account: configStore.contract,
      name: "claim",
      authorization: [{
        actor: authStore.wallet,
        permission: "active"
      }],
      data: {
        player: authStore.wallet
      }
    },
      {
        account: configStore.contract,
        name: "claimrewards",
        authorization: [{
          actor: authStore.wallet,
          permission: "active"
        }],
        data: {
          player_id: authStore.wallet
        }
      }
    ])
      .then((r) => {
        if(authStore.authenticator === "wax") {
          $toast.success("Multiple rewards Claimed!");
        }
        // setTimeout(playerStore.getPlayer, 3000);
      })
      .catch(error => {
        $toast.error(error.message)
      });
  };

  return {
    claimRewards,
    claimKyanite,
    claimPlanet,
    minePlanet,
    mineAll,
    sendAll,
    changeSpacestation,
    sendToPlanet,
    testMultipleTransactions,
    unequipComponents,
    equipComponent,
    enhancePlanet
  };
});
