import { defineStore } from "pinia";
import { ref } from "vue";
import axios from "axios";

export const useAtomicAssetsStore = defineStore("atomicassets", () => {

  const fetchedAssets = ref([]); //for smaller sets of assets
  const playerAssets = ref([]); // all player assets for starship game
  const templates = ref([]); // all player assets for starship game
  const hasFetchedAllPlayerAssets = ref(false);
  const hasFetchedTemplates = ref(false);
  const isLoading = ref(false);

  function getAsset(assetId) {
    // console.debug('getAsset('+assetId+')', fetchedAssets.value[assetId] || null)
    return fetchedAssets.value[assetId] || null;
  }

  function getAssets(assetIds) {
    // console.log('assetIds',assetIds)
    return fetchedAssets.value.find(asset => assetIds.contains(asset.asset_id) ) || [];
  }

  async function fetchAssets(assetIds, callback = undefined) {
    //Empty fetchedAssets
    fetchedAssets.value = [];

    //Early return if assetIds is not an array, or array is empty
    if(!Array.isArray(assetIds) || assetIds.length === 0) {
      return (callback && typeof callback === "function") ? callback(fetchedAssets.value) : [];
    }

    // build url
    // let url = "https://wax.api.atomicassets.io/atomicassets/v1/assets";
    let url = "https://aa-api-wax-mainnet-1.eu.aws.pink.gg/atomicassets/v1/assets";
      url += "?collection_name=starshipnfts";
      url += "&sort=asset_id";
      url += "&schema_name=component";
      url += "&asset_id="+assetIds.join(",");

    // make request, and save to our assets ref
    await axios.get(url)
      .then((response) => {
        // console.debug("asset "+assetIds.join(","), response.data);

        if(typeof response.data.data !== "undefined" && response.data.data.length > 0) {
          response.data.data.forEach(atomicAsset => {
            // console.log('asset', atomicAsset.asset_id, atomicAsset)
            // fetchedAssets.value[atomicAsset.asset_id] = atomicAsset;
            if(!fetchedAssets.value.find(asset => asset.asset_id === atomicAsset.asset_id)) {
              fetchedAssets.value.push(atomicAsset);
            }
          });
        }

        // callback, if set
        if(callback && typeof callback === "function") {
          return callback(fetchedAssets.value);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function fetchAllPlayerAssets(player) {
    //init
    let page = 1;
    let more = true;
    playerAssets.value = [];
    isLoading.value = true;

    // build url
    // let url = "https://wax.api.atomicassets.io/atomicassets/v1/assets";
    let url = "https://aa-api-wax-mainnet-1.eu.aws.pink.gg/atomicassets/v1/assets";
    url += "?collection_name=starshipnfts";
    url += "&schema_name=component";
    url += "&owner="+player;
    url += "&limit=50";

    // We need a way to page through multiple pages if player has many NFTs!!
    while(more === true && page < 100) {

      await axios.get(url + "&page=" + page)
        .then((response) => {
          if(typeof response.data.data !== "undefined" && response.data.data.length > 0) {
            response.data.data.forEach(asset => {
              // only save the details we need, because this is a massive data object and slows down splice with so many
              playerAssets.value.push({
                asset_id: asset.asset_id,
                owner: asset.owner,
                template: {
                  template_id: asset.template.template_id
                },
                data: asset.data,
                name: asset.name,
                used_for_enhancing: false,
              })
            })
          } else {
            more = false;
            isLoading.value = false;
            hasFetchedAllPlayerAssets.value = true; //to prevent UNECESARRY re-fetching for large collections
          }
        })
        .catch((error) => {
          console.error(error);
          more = false;
        })
        .finally(() => {
          page++;

          // // //TODO: Remove after dev - this just to make it quicker
          // // if(page >= 100) {
          // if(page >= 5) {
          //   more = false;
          //   isLoading.value = false;
          //   hasFetchedAllPlayerAssets.value = true;
          // }

        });
    }
  }

  async function fetchTemplates(template_ids) {
    //init
    templates.value = [];

    // build url
    // let url = "https://wax.api.atomicassets.io/atomicassets/v1/templates";
    let url = "https://aa-api-wax-mainnet-1.eu.aws.pink.gg/atomicassets/v1/templates";
    url += "?collection_name=starshipnfts";
    url += "&limit=100";

    while(template_ids.length) {
        await axios.get(url + "&template_id=" + template_ids.splice(0,100))
          .then((response) => {
            if(typeof response.data.data !== "undefined" && response.data.data.length > 0) {
              response.data.data.forEach(asset => {
                templates.value.push(asset)
              });
              if(template_ids.length === 0) {
                hasFetchedTemplates.value = true;
              }
            }
          })
          .catch((error) => {
            console.error(error);
          });
    }
  }

  return {
    fetchedAssets,
    playerAssets,
    templates,
    hasFetchedAllPlayerAssets,
    hasFetchedTemplates,
    isLoading,
    getAsset,
    getAssets,
    fetchAssets,
    fetchAllPlayerAssets,
    fetchTemplates,
  };
});
