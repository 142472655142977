<template>
  <div class="app-wrapper" :class="{'logged-in': authStore.isLoggedIn}">
    <!-- if logged in -->
    <template v-if="authStore.isLoggedIn">
      <NavbarOffcanvas />
<!--      <GlobalActions />-->
    </template>

    <RouterView v-slot="{ Component }">
<!--      <Transition name="fade" mode="out-in">-->
        <Component :is="Component" />
<!--      </Transition>-->
    </RouterView>

    <InfoBar />

    <template v-if="authStore.isLoggedIn">
      <SettingsModal />
      <SpacestationsModal />
      <MissionModal />
      <EnhancePlanetModal />
      <SendAllModal />
      <ComponentsModal />
      <div class="stars-backdrop">
        <div id="stars1"></div>
        <div id="stars2"></div>
        <div id="stars3"></div>
      </div>

    </template>
  </div>
</template>

<script setup>
// Note: @ is an alias to /src
import { useAuthStore } from "@/stores/AuthStore";
import { useShipsStore } from "@/stores/ShipsStore";
import { useRewardsStore } from "@/stores/RewardsStore";
import bus from "@/eventBus";
import router from "@/router";
import InfoBar from "@/components/InfoBar.vue";
import GlobalActions from "@/components/GlobalActions.vue";
import { onMounted, ref } from "vue";
import NavbarOffcanvas from "@/components/NavbarOffcanvas.vue";
import SettingsModal from "@/components/modals/SettingsModal.vue";
import SpacestationsModal from "@/components/modals/SpacestationsModal.vue";
import MissionModal from "@/components/modals/MissionModal.vue";
import EnhancePlanetModal from "@/components/modals/EnhancePlanetModal.vue";
import moment from "moment";
import SendAllModal from "./components/modals/SendAllModal.vue";
import { usePlayerStore } from "./stores/PlayerStore";
import { useAccountStore } from "./stores/AccountStore";
import ComponentsModal from "@/components/modals/ComponentsModal.vue";

const authStore = useAuthStore();
const shipsStore = useShipsStore();
const rewardsStore = useRewardsStore();
const playerStore = usePlayerStore();
const accountStore = useAccountStore();

const lastAutoRefresh = ref(0);

function autoRefresh() {
  if (authStore.isLoggedIn) {
    const now = moment.utc().unix();
    if(lastAutoRefresh.value + 60 < now) {
      updateLastAutoRefresh();

      // Nesting some setTimeouts to help reduce CORS errors due to too many simultaneous requests
      // TODO: find more elegant solution to queue multiple requests (with delays between queued calls)
      shipsStore.getShips().then(() => {
        setTimeout(() => {
          rewardsStore.getRewards();
        }, 300);
        setTimeout(() => {
          playerStore.getPlayer();
          accountStore.getAccount();
        }, 500);
      });

    }
  }
}

function updateLastAutoRefresh() {
  lastAutoRefresh.value = moment.utc().unix();
}

onMounted(() => {
  if(process.env.NODE_ENV !== "production" && import.meta.env.VITE_SHOW_MOUNTED_DEBUG === "true") {
    console.debug("App mounted");
  }
  if (!authStore.isLoggedIn) {
    router.push("/");
  }

  //Register event listener for window focus
  window.onfocus = function() {
    autoRefresh();
    bus.$emit('windowFocussed');
  }
});
</script>

<style>
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s ease-out;
}
</style>
