<template>
  <table class="table table-borderless table-sm m-0 resources">
    <tbody>
    <tr>
      <th class="py-0">CPU</th>
      <td width="100%"><ProgressBar :percent="accountStore.cpu" /></td>
    </tr>
    <tr>
      <th class="py-0">NET</th>
      <td><ProgressBar :percent="accountStore.net" /></td>
    </tr>
    <tr>
      <th class="py-0">RAM</th>
      <td><ProgressBar :percent="accountStore.ram" /></td>
    </tr>
    </tbody>
  </table>
</template>

<script setup>
import { useAccountStore } from "@/stores/AccountStore";
import ProgressBar from "./ProgressBar.vue";
const accountStore = useAccountStore();
</script>

