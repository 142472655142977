<template>
  <div class="card card-body planet" :class="'planet-'+planet.type">

    <div class="d-flex mb-3 align-items-center">
      <div class="text-center me-2">
        <img :src="`/images/planets/${planet.type}.webp`" :alt="planet.type" class="planet-img">
      </div>
      <div>
        <h3 class="text-center mb-0">{{capitalizeFirstLetter(planet.type)}}</h3>
      </div>
      <div class="ms-auto">
        <h4 class="text-white">{{planet.id}}</h4>
      </div>
    </div>

    <div class="planet-stats mb-3">
      <div class="progress mb-2">
        <div class="progress-bar" :class="progressClass" role="progressbar" :style="{width: percentPool+'%'}" :aria-valuenow="percentPool" aria-valuemin="0" aria-valuemax="100"><span :class="{'text-danger' :percentPool === 0}">{{progressText}}</span></div>
      </div>
      <p><b>Pool</b>: {{ roundToTwo(pool) }} / {{ separateThousands(maxPool) }}</p>
      <p><b>Name</b>: {{ planetName }}</p>
      <p><b>Owner</b>: {{ owner }}</p>
      <p v-if="owner === 'Unclaimed'"><b>Cost to Claim</b>: <span class="text-primary">{{ claimCost }}</span><br><small class="text-light-grey">(max_pool x {{roundToTwo(planetClaimPercent)}}%)</small></p>
      <p><b>Next Reset</b>: {{ nextPoolReset }}</p>
<!--      TODO: Add fuel cost (and rental fuel cost)-->
<!--      TODO: Highlight if the player owns this planet-->
    </div>

    <button class="btn btn-lg btn-primary" @click="clickSend">Launch <i class="bi bi-rocket-takeoff ms-2"></i></button>
    <hr class="my-3">

    <div class="d-flex justify-content-between align-items-center">
      <div class="text-bold">Pre-set:</div>
      <button class="btn btn-special btn-sm" @click.prevent.stop="clickSavePreset" v-if="!isPresetPlanet">
        Preset Ship
      </button>
      <button class="btn btn-outline-pink btn-sm" @click.prevent.stop="clickSavePresetAll" v-if="!isPresetPlanet">
        Preset All Ships
      </button>


      <button class="btn btn-success btn-sm text-bold flex-grow-1 mx-2" v-if="isPresetPlanet">
        <i class="bi bi-check"></i> Planet selected
      </button>
      <div>
        <button class="btn btn-sm btn-outline-danger" @click.prevent.stop="clickCancelPreset" v-if="isPresetPlanet">
          <i class="bi bi-x"></i> Cancel
        </button>
      </div>

    </div>


  </div>
</template>

<script setup>
import { computed, onMounted } from "vue";
import { usePlanetsStore } from "../../stores/PlanetsStore";
import moment from "moment/moment";

const planetsStore = usePlanetsStore();

const props = defineProps({
  planet: {
    type: Object,
    default: {}
  },
  shipPlanetPreset: {
    default: null
  },
});

const emit = defineEmits(["clickSend", "savePreset", "cancelPreset", "savePresetForAll"]);

const pool = computed(() => {
  if(moment().unix() > props.planet.next_reset) {
    return parseFloat(maxPool.value);
  } else {
    return parseFloat(props.planet.pool);
  }
});

const maxPool = computed(() => {
  return parseFloat(props.planet.max_pool);
});

const isPresetPlanet = computed(() => {
  return props.shipPlanetPreset === props.planet.id;
});

const planetName = computed(() => {
  return planetsStore.planetName(props.planet.id);
});

const percentPool = computed(() => {
  return roundToTwo(parseFloat(pool.value/maxPool.value*100));
});

const progressClass = computed(() => {
  if(percentPool.value <= 25) {
    return 'bg-danger progress-bar-striped';
  } else if(percentPool.value <= 50) {
    return 'bg-warning';
  }  else if(percentPool.value <= 75) {
    return 'bg-info';
  } else {
    return 'bg-success';
  }
});

const progressText = computed(() => {
  return percentPool.value > 0 ? percentPool.value + '%' : 'EMPTY!';
});

const owner = computed(() => {
  return props.planet.owner === 'starshipgame' ? 'Unclaimed' : props.planet.owner;
});

const nextPoolReset = computed(() => {
  if(moment().unix() > props.planet.next_reset) {
    let time = props.planet.next_reset;
    if(props.planet.owner === 'starshipgame') {
      time += 60*60*24*365*10; //increment 10yrs in seconds
      // time += 60*60*24*2784; //increment 2784 days similar to Dexter planets in seconds
    } else {
      let plus24hours = 60*60*24; //increment 24hrs in seconds
      time = moment().utc().startOf('day').unix()  + plus24hours;
    }
    return moment.unix(time).format('D MMM YYYY, HH:mm');
  } else {
    return moment.unix(props.planet.next_reset).format('D MMM YYYY, HH:mm')
  }
});

function clickSavePreset() {
  emit("savePreset", props.planet.id)
}

function clickSavePresetAll() {
  emit("savePresetForAll", props.planet.id)
}

function clickCancelPreset() {
  emit("cancelPreset", props.planet.id)
}

function clickSend() {
  emit("clickSend", props.planet.id);
}

//TODO: Can we move this to a global helper somewhere
function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
function roundToTwo(num) {
  return (+(Math.round(parseFloat(num) + "e+2")  + "e-2")).toLocaleString();
}
function separateThousands(num) {
  return parseFloat(num).toLocaleString();
}

onMounted(() => {
  if(process.env.NODE_ENV !== "production" && import.meta.env.VITE_SHOW_MOUNTED_DEBUG === "true") {
    console.debug(`MissionPlanet mounted`);
  }

});
</script>