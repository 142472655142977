<template>
  <nav class="navbar sticky-top navbar-expand-md navbar-dark bg-dark" id="mainNav">
    <div class="container-fluid">
      <button class="navbar-toggler me-3" type="button" @click="openOffcanvas" aria-controls="offcanvasDarkNavbar">
        <span class="navbar-toggler-icon"></span>
      </button>
      <span class="navbar-brand mb-0 h1 text-white me-auto me-md-3"><span class="text-pirate">Pirate</span>Ship</span>



      <div class="offcanvas offcanvas-start text-bg-dark" tabindex="-1" id="offcanvasDarkNavbar" aria-labelledby="offcanvasDarkNavbarLabel">
        <div class="offcanvas-header">
<!--          <h5 class="offcanvas-title" id="offcanvasDarkNavbarLabel">Dark offcanvas</h5>-->
          <button type="button" class="btn-close btn-close-white me-4" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          <span class="navbar-brand mb-0 h1 text-white me-auto"><span class="text-pirate">Pirate</span>Ship</span>
        </div>

        <div class="offcanvas-mobile d-md-none p-3">


          <!--        <div class="offcanvas-profile d-md-none">-->
          <!--          <span class="badge bg-warning" v-if="authStore.authenticator === 'wax'">WAX</span>-->
          <!--          <span class="badge bg-primary" v-else-if="authStore.authenticator === 'anchor'">Anchor</span>-->
          <!--          <span class="account-name">{{ authStore.wallet }}</span>-->
          <!--        </div>-->
          <div class="  d-flex mx-auto  align-items-center">
            <span class="profile-icon me-3" :class="iconClass">
            <img :src="'https://robohash.org/'+authStore.wallet+'.png?set=set3&size=100x100'" :alt="authStore.wallet" class="img-fluid">
          </span>
            <span class="account-name">{{ authStore.wallet }}</span>
          </div>

<!--          <h3 class="mt-3">Balances</h3>-->
          <div class="mt-3">
            <BalancesTable />
            <hr>
            <ResourcesTable />
          </div>

        </div>


        <div class="offcanvas-body">

          <ul class="navbar-nav justify-content-start flex-grow-1 pe-3">
            <li class="nav-item mobile-only-icon">
              <router-link to="/spaceport" class="nav-link" :class="{'active':$route.name==='spaceport'}" @click="navLinkClicked"><i class="bi bi-rocket"></i>Spaceport</router-link>
            </li>
<!--            <li class="nav-item">-->
<!--              <router-link to="/planetarium" class="nav-link" :class="{'active':$route.name}" @click="navLinkClicked">Planetarium</router-link>-->
<!--            </li>-->
<!--            <li class="nav-item">-->
<!--              <router-link to="/my-planets" class="nav-link" :class="{'active':$route.name==='my-planets'}" @click="navLinkClicked"><i class="bi bi-globe-asia-australia"></i>My Planets</router-link>-->
<!--            </li>-->
<!--            <li class="nav-item">-->
<!--              <router-link to="/leaderboards" class="nav-link" :class="{'active':$route.name==='leaderboards'}" @click="navLinkClicked"><i class="bi bi-list-ol"></i>Leaderboards</router-link>-->
<!--            </li>-->
<!--            <li class="nav-item">-->
<!--              <router-link to="/shop" class="nav-link" :class="{'active':$route.name}" @click="navLinkClicked">Shop</router-link>-->
<!--            </li>-->

            <!-- mobile only -->
            <li class="nav-item d-md-none"><a href="#" class="nav-link " data-bs-toggle="modal" data-bs-target="#settingsModal" @click.prevent="navLinkClicked"><i class="bi bi-gear"></i>Settings</a></li>
            <li class="nav-item d-md-none"><a href="#" class="nav-link text-danger" @click.stop.prevent="doLogout"><i class="bi bi-box-arrow-right"></i>Logout</a></li>

            <!-- desktop only -->
            <li class="nav-item ms-auto me-2 dropdown d-none d-md-block">
              <BalancesDropdown />
            </li>
            <li class="nav-item me-2 dropdown d-none d-md-block">
              <WaxResourcesDropdown />
            </li>
            <li class="nav-item dropdown d-none d-md-block">
              <a class="nav-link p-0 dropdown-toggle d-flex align-items-center" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false" id="profileDropdown" @click="toggleDropdown">
                <span class="profile-icon me-2" :class="iconClass">
                  <img :src="'https://robohash.org/'+authStore.wallet+'.png?set=set3&size=100x100'" :alt="authStore.wallet" class="img-fluid">
                </span>

<!--                <span class="badge bg-warning" v-if="authStore.authenticator === 'wax'">WAX</span>-->
<!--                <span class="badge bg-primary" v-else-if="authStore.authenticator === 'anchor'">Anchor</span>-->
                <span class="wallet-name">{{ authStore.wallet }}</span>
              </a>
              <ul class="dropdown-menu dropdown-menu-dark dropdown-menu-end text-end">
                <li><a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#settingsModal">Settings <i class="bi bi-gear ms-2"></i></a></li>
                <li><hr class="dropdown-divider"></li>
                <li><a href="#" class="dropdown-item text-danger" @click.stop.prevent="doLogout">Logout <i class="bi bi-box-arrow-right ms-2"></i></a></li>
              </ul>
            </li>
<!--            <li class="nav-item">-->
<!--              <div class="dropdown">-->
<!--                <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">-->
<!--                  Dropdown button-->
<!--                </button>-->
<!--                <ul class="dropdown-menu">-->
<!--                  <li><a class="dropdown-item" href="#">Action</a></li>-->
<!--                  <li><a class="dropdown-item" href="#">Another action</a></li>-->
<!--                  <li><a class="dropdown-item" href="#">Something else here</a></li>-->
<!--                </ul>-->
<!--              </div>-->
<!--            </li>-->
          </ul>
        </div>
      </div>
    </div>
  </nav>
</template>

<script setup>
import { useAuthStore } from "@/stores/AuthStore";
import { computed, onMounted, onUnmounted } from "vue";
// import bootstrap from "bootstrap/dist/js/bootstrap.bundle.min"
import * as bootstrap from "bootstrap/dist/js/bootstrap.bundle.min";
// import * as bootstrap from "bootstrap/dist/js/bootstrap.esm.min";
import bus from "@/eventBus";
import BalancesTable from "./BalancesTable.vue";
import ResourcesTable from "./ResourcesTable.vue";
import WaxResourcesDropdown from "@/components/WaxResourcesDropdown.vue";
import BalancesDropdown from "@/components/BalancesDropdown.vue";

let offCanvasElem;
let offCanvasInstance;
let dropdownElem;
let dropdownInstance;

const authStore = useAuthStore();

const iconClass = computed(() => {
  if(authStore.authenticator === 'wax') {
    return 'border-wax';
  } else if(authStore.authenticator === 'anchor') {
    return 'border-anchor';
  } else {
    return 'border-default';
  }
});

// function toggleDropdown() {
//   const dropdownElementList = document.querySelectorAll('.dropdown-toggle')
//   const dropdownList = [...dropdownElementList].map(dropdownToggleEl => new bootstrap.Dropdown(dropdownToggleEl))
// }

function toggleDropdown() {
  dropdownInstance = bootstrap.Dropdown.getOrCreateInstance(dropdownElem);
  dropdownInstance.toggle();
}

function openDropdown() {
  dropdownInstance = bootstrap.Dropdown.getOrCreateInstance(dropdownElem);
  dropdownInstance.show();
}

function openOffcanvas() {
  offCanvasInstance = bootstrap.Offcanvas.getOrCreateInstance(offCanvasElem);
  offCanvasInstance.show();
}

function openSettings() {
  navLinkClicked();
  //TODO: Open a settings modal
  console.log('TODO: Open settings modal');

}

async function doLogout() {
  // navLinkClicked();
  await navLinkClicked();
  authStore.logout();
}

async function navLinkClicked() {
  document.querySelector("body").style.overflow = null;
  // This should be able to do multiple "things"... like close off-canvas, and modals etc
  await hideOffCanvas();
  // more...
}

async function hideOffCanvas() {
  // console.log("hiding");
  if(offCanvasInstance) {
    await offCanvasInstance.hide();
  }
}

onUnmounted(() => {
  bus.$off('closeAll');
});

onMounted(() => {
  if(process.env.NODE_ENV !== "production" && import.meta.env.VITE_SHOW_MOUNTED_DEBUG === "true") {
    console.debug("NavbarOffCanvas mounted");
  }

  offCanvasElem = document.getElementById('offcanvasDarkNavbar');
  offCanvasInstance = bootstrap.Offcanvas.getOrCreateInstance(offCanvasElem);

  dropdownElem = document.getElementById('profileDropdown');
  dropdownInstance = bootstrap.Dropdown.getOrCreateInstance(dropdownElem);

  // offCanvasElem.addEventListener('hidden.bs.offcanvas', () => {
  //   hideOffCanvas();
  // });

  bus.$on('closeAll', (args) => {
    // console.log('bus.$on.closeAll', args);
    hideOffCanvas();
  });

});
</script>