<template>
  <Modal
    id="settingsModal"
    title="Settings"
    :on-close="closeModal"
    class=""
    size-class="modal-lg"
    :use-footer="false"
  >
    <template #body>

      <p class="text-success">Settings should take effect straight away. No need for a save button :)</p>

      <div class="form-group mb-3">
        <label for="rpc_select">Select RPC Node</label>
        <select name="rpc_select" id="rpc_select" class="form-select me-auto" v-model="rpcNode"
                @change="saveRpcNode" style="max-width: 300px">
          <option v-for="item in endpoints.rpcNodes" :value="item.value" selected>{{ item.name }}</option>
        </select>
        <span class="help">RPC Node is where transactions on the contract are executed.</span>
      </div>

      <div class="form-group mb-3">
        <label for="api_select">Select API Endpoint</label>
        <select name="api_select" id="api_select" class="form-select me-auto" v-model="apiNode"
                @change="saveApiNode" style="max-width: 300px">
          <option v-for="item in endpoints.apiNodes" :value="item.value" selected>{{ item.name }}</option>
        </select>
        <span class="help">API Endpoints are where contract table data is queried.</span>
      </div>

      <div class="form-group mb-3">
        <label for="api_select">Select Theme</label>
        <select name="theme_select" id="theme_select" class="form-select me-auto" v-model="theme"
                @change="saveTheme" style="max-width: 300px">
          <option v-for="item in themes" :value="item.slug" selected>{{ item.name }}</option>
        </select>
        <span class="help">Choose a whole new theme for the game UI. More themes coming over time...</span>
      </div>

    </template>

  </Modal>
</template>

<script setup>
import { onMounted, onUnmounted, ref } from "vue";
import Modal from "@/components/Modal.vue";
import useEndpoints from "@/config/endpoints.json";
import { useThemes } from "@/config/themes.js";
import { useConfigStore } from "@/stores/ConfigStore";
import { useAuthStore } from "@/stores/AuthStore";
import bus from "@/eventBus";
import * as bootstrap from "bootstrap/dist/js/bootstrap.esm.min";

const authStore = useAuthStore();
const configStore = useConfigStore();

const endpoints = useEndpoints;
const themes = useThemes;

let settingsModal;

const rpcNode = ref("");
const apiNode = ref("");
const theme = ref("");

const closeModal = () => {
  bus.$emit("closeAll");
  settingsModal.hide();
};

const openModal = () => {
  settingsModal.show();
};

function saveRpcNode(e) {
  configStore.setRpcNode(e.target.value);
}

function saveApiNode(e) {
  configStore.setApiNode(e.target.value);
}

function saveTheme(e) {
  configStore.setTheme(e.target.value);
}

onUnmounted(() => {
  bus.$off('openSettingsModal');
  bus.$off('closeSettingsModal');
});

onMounted(() => {
  if(process.env.NODE_ENV !== "production" && import.meta.env.VITE_SHOW_MOUNTED_DEBUG === "true") {
    console.debug("SettingsModal mounted");
  }

  apiNode.value = configStore.apiNode;
  rpcNode.value = configStore.rpcNode;
  theme.value = configStore.theme;

  bus.$on('openSettingsModal', (ship) => {
    console.log('bus.$on.openSettingsModal', ship);
    openModal();
  });

  bus.$on('closeSettingsModal', (args) => {
    console.log('bus.$on.closeSettingsModal', args);
    closeModal();
  });

  settingsModal = new bootstrap.Modal(document.getElementById('settingsModal'));

  const settingsModalElem = document.getElementById('settingsModal')
  settingsModalElem.addEventListener('hidden.bs.modal', () => {
    //
  });
});
</script>