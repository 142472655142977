<template>
  <Modal
    id="missionModal"
    :title="modalTitle"
    :on-close="closeModal"
    class=""
    size-class="modal-lg modal-fullscreen-lg-down"
    :use-footer="false"
  >
    <template #body>

      <!-- Nav tabs -->
      <ul class="nav nav-pills nav-fill nav-pills-secondary" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
          <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#byDefault" type="button" role="tab" aria-controls="default" aria-selected="true">Coordinate Dropdowns</button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#byId" type="button" role="tab" aria-controls="id" aria-selected="false">By Planet ID</button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link" id="preset-tab" data-bs-toggle="tab" data-bs-target="#byPreset" type="button" role="tab" aria-controls="preset" aria-selected="false" @click="showSendAllTab">Pre-set for Send All</button>
        </li>
      </ul>
      <hr>
      <!-- Tab panes -->
      <div class="tab-content">
        <div class="tab-pane active" id="byDefault" role="tabpanel" aria-labelledby="default-tab" tabindex="0">
          <h4 class="mt-3">Select Location</h4>
          <p class="help">Select a location using the dropdowns to find your planet.</p>

          <div class="row mb-3">
            <div class="col-md-4">
              <div class="form-group mb-2">
                <label for="selectQuadrant">Quadrant</label>
                <select name="quadrant" id="selectQuadrant" class="form-select" v-model="selectedQuadrant" @change="updatePlanets">
                  <option value="">Select a quadrant</option>
                  <option v-for="(option, index) in quadrantList" :key="index" :value="index">{{ option }}</option>
                </select>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group mb-2">
                <label for="selectSector">Sector</label>
                <select name="sector" id="selectSector" class="form-select" v-model="selectedSector" @change="updatePlanets">
                  <option value="">Select a sector</option>
                  <option v-for="option in sectorList" :key="option" :value="option">{{ option }}</option>
                </select>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group mb-2">
                <label for="selectSystem">System</label>
                <select name="system" id="selectSystem" class="form-select" v-model="selectedSystem" @change="updatePlanets">
                  <option value="">Select a system</option>
                  <option v-for="option in systemList" :key="option" :value="option">{{ option }}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 mb-3" v-for="planet in planetsStore.missionPlanets">
              <MissionPlanet :planet="planet" :key="planet.id" :ship-planet-preset="presetPlanetId" @clickSend="emittedClickSend" @savePreset="emittedSavePreset" @savePresetForAll="emittedSavePresetForAll" @cancelPreset="emittedCancelPreset" />
            </div>
          </div>
        </div>

        <div class="tab-pane " id="byId" role="tabpanel" aria-labelledby="id-tab" tabindex="0">
          <h4 class="mt-3">Select Planet ID</h4>
          <p class="help">Lookup a planet by it's ID, and then choose to fly your ship there.</p>
          <div class="input-group mb-3">
            <input type="number" class="form-control no-html5-controls" id="planet_id" v-model="manualPlanetId" placeholder="Enter Planet ID..." @keyup="onPlanetIdInputChange">
            <button class="btn btn-outline-secondary" @click="planetLookup" :disabled="!manualPlanetIdSubmitEnabled"><i class="bi bi-search"></i></button>
          </div>

          <div class="alert alert-danger" v-if="manualPlanetIdError">{{ manualPlanetIdError }}</div>

          <div class="row" v-if="planetLookupPlanet">
            <div class="col-md-6 mb-3" >
              <MissionPlanet :planet="planetLookupPlanet" :key="planetLookupPlanet.id" :ship-planet-preset="presetPlanetId" @clickSend="emittedClickSend" @savePreset="emittedSavePreset" @savePresetForAll="emittedSavePresetForAll" @cancelPreset="emittedCancelPreset" />
            </div>
          </div>
        </div>

        <div class="tab-pane" id="byPreset" role="tabpanel" aria-labelledby="preset-tab" tabindex="0">
          <h4 class="mt-3">Pre-set for <span class="text-special text-bold">Send All</span></h4>
          <p class="help">To use the <span class="text-special text-bold">Send All</span> action to send all your ships at once you will need to pre-set the
            destination planet for each ship.</p>

          <div class="row" v-if="presetPlanet">
            <div class="col-md-6 mb-3" >
              <MissionPlanet :planet="presetPlanet" :key="presetPlanet.id" :ship-planet-preset="presetPlanetId" @clickSend="emittedClickSend" @savePreset="emittedSavePreset" @savePresetForAll="emittedSavePresetForAll" @cancelPreset="emittedCancelPreset" />
            </div>
          </div>

          <div class="alert alert-info mt-3 text-center" v-if="!presetPlanetId">
            <p>This ship does not have a preset planet yet!</p>
            <p>You can set one in the other tabs by clicking the "Save Preset" link on any planet result.</p>
          </div>
        </div>
      </div>


    </template>

  </Modal>
</template>

<script setup>
import { useToast } from "vue-toast-notification";
import { computed, onMounted, onUnmounted, ref, watch } from "vue";
import Modal from "@/components/Modal.vue";
import useSpacestationsJSON from "@/config/spacestations.json";
import { usePlayerActionsStore } from "@/stores/actions/PlayerActionsStore";
import { useShipsStore } from "@/stores/ShipsStore";
import { usePlanetsStore } from "@/stores/PlanetsStore";
import { useConfigStore } from "@/stores/ConfigStore";
import { useAuthStore } from "@/stores/AuthStore";
import bus from "@/eventBus";
import * as bootstrap from "bootstrap/dist/js/bootstrap.esm.min";
import MissionPlanet from "@/components/planets/MissionPlanet.vue";

const $toast = useToast({ position: "top-right" });
const authStore = useAuthStore();
const configStore = useConfigStore();
const playerActionsStore = usePlayerActionsStore();
const shipsStore = useShipsStore();
const planetsStore = usePlanetsStore();
const spacestationsJSON = useSpacestationsJSON;

let missionModal;

const shipId = ref("");
const manualPlanetId = ref("");
const selectedQuadrant = ref("");
const selectedSector = ref("");
const selectedSystem = ref("");

const planetLookupPlanet = ref(null);
const manualPlanetIdError = ref("");

const quadrantList = computed(() => [
  'North',
  'West',
  'East',
  'South',
]);
const sectorList = computed(() => [...Array(25).keys()]);
const systemList = computed(() => [...Array(25).keys()]);
const presetPlanetId = computed(() => shipsStore.getShipPreset(shipId.value));
const presetPlanet = computed(() => planetsStore.planet(presetPlanetId.value));


const updatePlanets = () => {
  // console.log("updatePlanets");
  const ship = shipsStore.ship(shipId.value);
  const spacestationId = ship ? ship.spacestation : null;

  // console.table({
  //   spacestation: spacestationId,
  //   quadrant: selectedQuadrant.value,
  //   sector: selectedSector.value,
  //   system: selectedSystem.value,
  //   planet: null,
  // });

  if(spacestationId !== "" && spacestationId !== null &&
    selectedQuadrant.value !== "" && selectedQuadrant.value !== null &&
    selectedSector.value !== "" && selectedSector.value !== null &&
    selectedSystem.value !== "" && selectedSystem.value !== null
  ) {
    // Get Mission Planets from a call in the PlanetsStore
    planetsStore.getMissionPlanets(spacestationId, selectedQuadrant.value, selectedSector.value, selectedSystem.value);
  }
}

const loadPresetPlanet = () => {
  if(presetPlanetId.value) {
    planetsStore.getPlanet(presetPlanetId.value);
  }
};

const showSendAllTab = () => {
  // console.log('show send all tab');
  loadPresetPlanet();
};

const closeModal = () => {
  missionModal.hide();
};

const openModal = () => {
  // console.log('open modal');
  missionModal.show();
  loadPresetPlanet();
};

const onPlanetIdInputChange = (e) => {
  manualPlanetIdError.value = "";
  planetLookupPlanet.value = null;

  const inputLength = manualPlanetId.value.toString().length;

  //make sure we only have max 7 chars
  manualPlanetId.value = manualPlanetId.value.toString().slice(0,7);

  if(inputLength === 0) {
    return;
  }

  if(planetsStore.planetSpacestation(manualPlanetId.value) !== spacestationID.value) {
    manualPlanetIdError.value = "Planet out of range! Planet either does not exist or is located at another spacestation.";
    return;
  }

  // Attempt to get planet
  if(inputLength > 1 && inputLength <= 7) {
     planetsStore.getPlanet(manualPlanetId.value, (planet) => {
       planetLookupPlanet.value = planet;
     });
  }
};

const manualPlanetIdSubmitEnabled = computed(() => manualPlanetId.value.toString().length >= 6);

const spacestationID = computed(() => {
  const ship = shipsStore.ship(shipId.value);
  return ship.spacestation || null;
});

const modalTitle = computed(() => {
  let string = "Mission Select";
  if(shipId.value) {
    string += ` for Ship ${shipId.value}`;
  }
  return string;
});

function emittedClickSend(planetId) {
  playerActionsStore.sendToPlanet(shipId.value, planetId);
}

function emittedSavePreset(planetId) {
  shipsStore.presetPlanetForShip(shipId.value, planetId);
}

function emittedSavePresetForAll(planetId) {
  shipsStore.presetPlanetForAllShips(planetId);
}

function emittedCancelPreset() {
  shipsStore.presetPlanetForShip(shipId.value, null);
}
function planetLookup() {
  manualPlanetIdError.value = "";
  if(manualPlanetId.value) {
    if(planetsStore.planetSpacestation(manualPlanetId.value) !== spacestationID.value) {
      manualPlanetIdError.value = "Planet out of range! Planet either does not exist or is located at another spacestation.";
      return;
    }
    // playerActionsStore.sendToPlanet(shipId.value, manualPlanetId.value);
  } else {
    $toast.warning("You need to specify a Planet ID!");
  }
}

// const selectSpacestation = (spacestation) => {
//   if(spacestationID.value !== parseInt(spacestation)) {
//     playerActionsStore.changeSpacestation(shipId.value, spacestation);
//   }
// };

onUnmounted(() => {
  bus.$off('openMissionModal');
  bus.$off('closeMissionModal');
});

onMounted(() => {
  if(process.env.NODE_ENV !== "production" && import.meta.env.VITE_SHOW_MOUNTED_DEBUG === "true") {
    console.debug("MissionModal mounted");
  }

  bus.$on('openMissionModal', (ship) => {
    // console.log('bus.$on.openMissionModal', ship);
    shipId.value = ship;
    updatePlanets();
    openModal();
  });

  bus.$on('closeMissionModal', (args) => {
    // console.log('bus.$on.closeMissionModal', args);
    closeModal();
  });

  missionModal = new bootstrap.Modal(document.getElementById('missionModal'));

  const missionModalEl = document.getElementById('missionModal')
  missionModalEl.addEventListener('hidden.bs.modal', () => {shipId.value = ""});

  //init values (not necesarry, but saves a few clicks
  selectedQuadrant.value = "0";
  selectedSector.value = "0";
  selectedSystem.value = "0";

});
</script>
