<template>
  <div class="card card-planet-enhance-row p-1 mb-2" v-if="assets.length > 0 || showAll">
    <div class="grid-planet-enhance-row">
      <div class="template-details">
        <div>
          <div style="width: 50px;">
            <img :src="`/images/nfts/${enhancement.template_id}.png`" class="img-fluid" :alt="`${rarity?.name} ${template.name}`">
          </div>
        </div>

        <div class="name-and-rarity">
          <div class="text-bold">{{ template?.name }}</div>
          <div>
            <RarityBadge :rarity="rarityKey" v-if="rarityKey >= 0" />
          </div>
        </div>
      </div>

      <div class="col-increase">
        <span class="label">Increase:</span>
        <span v-if="enhancement.amountorpercentage">+<b>{{ percentage }}</b> %</span>
        <span v-else>+<b>{{enhancement.up_amount}}</b></span>
      </div>
      <div class="col-return">
        <span class="label">Auto-return:</span>
        <i class="bi bi-check-lg text-lg text-bold text-success" v-if="enhancement.auto_return"></i>
        <i class="bi bi-x-lg text-lg text-bold text-danger" v-else></i>
      </div>
      <div class="col-nfts" :class="{'text-danger':assets.length === 0}">
        <span class="label">NFTs:</span>
        {{ assets.length }}
      </div>
      <div><button v-if="assets.length > 0" class="btn btn-sm btn-outline-primary" title="View available NFTs" @click="toggleAvailableAssets"><i class="bi bi-eye-fill"></i> View NFTs</button></div>
    </div>

    <div v-if="showAssets"  class="position-relative">
      <hr>

      <template v-if="assets.length > 0">
        <p class="help">Click your NFT to enhance your planet</p>
        <div class="grid-enhance-planet-chooser" v-if="assets.length > 0" style="min-height: 50px;">
          <AvailableAsset v-for="asset in assets"
                          :key="asset.asset_id"
                          :asset="asset"
                          @click="emitEnhance"
          ></AvailableAsset>
        </div>
      </template>

      <div class="alert alert-info w-100" v-else>
        <p class="mb-0 text-center">No {{rarity?.name}} {{template?.name}} NFTs found!</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import RarityBadge from "@/components/ship/RarityBadge.vue";
import rarities from "@/config/rarities.json"
import helpers from "@/helpers/global-helpers";
import AvailableAsset from "@/components/modals/EnhancePlanetModal/AvailableAsset.vue";

const props = defineProps({
  enhancement: {
    type: Object,
    default: {}
  },
  template: {
    type: Object,
    default: {}
  },
  assets: {
    type: Array,
    default: {}
  },
  showAll: {
    type: Boolean,
    default: false
  },
});

const emit = defineEmits(["enhance"]);

const showAssets = ref(false);

const rarityKey = computed(() => parseInt(props.template?.immutable_data?.rdata));

const rarity = computed(() => rarities.find(r => r.key === rarityKey.value));

const percentage = computed(() => {
  const percent = parseFloat(props.enhancement.up_percentage) * 100;
  return percent < 1 ? helpers.round(percent, 2) : Math.round(percent)
});

const toggleAvailableAssets = () => {
  showAssets.value = !showAssets.value;
};

const emitEnhance = (assetId) => {
  emit("enhance", assetId, props.enhancement);
};

onMounted(() => {
  if(process.env.NODE_ENV !== "production" && import.meta.env.VITE_SHOW_MOUNTED_DEBUG === "true") {
    console.debug(`PlanetEnhancementGroup mounted`);
  }

});
</script>