<template>
  <div class="container">

    <div class="login-logo text-center">
      <img src="/images/PirateShip-text-logo.png" alt="PirateShip Logo" width="1030" height="228"
           class="img-fluid mx-auto">
    </div>

    <div class="row mb-4">
      <div class="col-10 mx-auto">
        <div class="login-intro text-center">
          <h1 class="visually-hidden">Welcome to <b>PirateShip</b></h1>
<!--          <h2>What is it?</h2>-->
          <p>PirateShip is a brand new <b>unofficial</b> custom game frontend for the awesome Starship NFT game.
            It uses the official Starship WAX blockchain contract, but with a completely custom UI, so now you can pilot
            your fleet
            and mine all that glorious Kyanite, but with extra features and improvements that only Primate Pirate
            can deliver.</p>
        </div>
      </div>
    </div>

      <div class=" mx-auto mb-5" style="max-width: 500px;">

        <div class="card text-center mb-3">
          <div class="card-header">
            <h3 class="card-title">Login / Register</h3>
          </div>
          <div class="card-body">
            <div class="alert alert-info" v-if="accessPassAccessAllowed">
              <h4>PIRATESHIP Access Pass holders ONLY!</h4>
              <p class="mb-0">You must own a Primate Pirate PIRATESHIP Access Pass NFT in the wallet you are logging in with to access PirateShip.</p>
            </div>
            <div class="alert alert-info" v-else>
              <h4 class="text-danger">WHITELIST ONLY!</h4>
              <p class="mb-0">You must be whitelisted by Primate Pirate to log in.</p>
            </div>
            <div class="d-flex flex-wrap align-items-center justify-content-center mb-2">
              <button class="btn btn-lg btn-ual btn-wax-cloud-wallet m-2" @click="authStore.loginWax()">
                <span class="logo-wrapper"><WaxLogo /></span>
                <span class="text">WAX Cloud Wallet</span>
                <span class="icon-wrapper"><i class="bi bi-chevron-double-right" aria-hidden="true"></i></span>
              </button>

              <button class="btn btn-lg btn-ual btn-anchor m-2 " @click="authStore.loginAnchor()">
                <span class="logo-wrapper"><AnchorLogo /></span>
                <span class="text">Anchor Wallet</span>
                <span class="icon-wrapper"><i class="bi bi-chevron-double-right" aria-hidden="true"></i></span>
              </button>
            </div>
            <!--                <p class="fw-bold text-danger">Players must own a Primate Pirate GENESIS Collection NFT in their connecting wallet in order to log into this game client!</p>-->
            <a href="#" class="d-inline-block mb-3"
               @click.prevent="toggleLoginOptions">{{ showLoginOptions ? "Hide" : "Show" }} advanced login options
              <i v-if="showLoginOptions" class="bi bi-chevron-up"></i>
              <i v-else class="bi bi-chevron-down"></i>
            </a>

            <Transition>
              <div v-if="showLoginOptions">
                <div class="form-group mb-3">
                  <label for="rpc_select">Select RPC Node</label>
                  <select name="rpc_select" id="rpc_select" class="form-select mx-auto" v-model="rpcNode"
                          @change="saveRpcNode" style="max-width: 300px">
                    <option v-for="item in endpoints.rpcNodes" :value="item.value" selected>{{ item.name }}</option>
                  </select>
                  <span class="help">RPC Node is where transactions on the contract are executed.</span>
                </div>
                <div class="form-group mb-3">
                  <label for="api_select">Select API Endpoint</label>
                  <select name="api_select" id="api_select" class="form-select mx-auto" v-model="apiNode"
                          @change="saveApiNode" style="max-width: 300px">
                    <option v-for="item in endpoints.apiNodes" :value="item.value" selected>{{ item.name }}</option>

                  </select>
                  <span class="help">API Endpoints are where contract table data is queried.</span>
                </div>
              </div>
            </Transition>
          </div>

        </div>

      </div>

      <div class="alert alert-warning text-center small">
        <h3>Warning/Disclaimer</h3>
        <p>This is NOT an official Starship product! This is built by Primate Pirate and is not supported by the
          official Starship team.</p>
        <p>Whitelisted players use this website at their own risk. Primate Pirate accepts no responsibility for any losses
          or damage caused by playing Starship NFT game or using this custom frontend!</p>
      </div>


  </div>
</template>

<script setup>
import { useAuthStore } from "@/stores/AuthStore";
import { useConfigStore } from "@/stores/ConfigStore";
import router from "@/router";
import WaxLogo from "@/components/icons/WaxLogo.vue";
import AnchorLogo from "@/components/icons/AnchorLogo.vue";
import { computed, onMounted, ref } from "vue";
import useEndpoints from "@/config/endpoints.json";

const authStore = useAuthStore();
const configStore = useConfigStore();
const endpoints = useEndpoints;

const rpcNode = ref("");
const apiNode = ref("");
const showLoginOptions = ref(false);

const accessPassAccessAllowed = computed(() => import.meta.env.VITE_ALLOW_ACCESS_PASS_ACCESS === "true");

function toggleLoginOptions() {
  showLoginOptions.value = !showLoginOptions.value;
}

function saveRpcNode(e) {
  configStore.setRpcNode(e.target.value);
}

function saveApiNode(e) {
  configStore.setApiNode(e.target.value);
}


onMounted(() => {
  if(process.env.NODE_ENV !== "production" && import.meta.env.VITE_SHOW_MOUNTED_DEBUG === "true") {
    console.debug("LoginView mounted");
  }

  document.querySelector("body").style.overflow = null;
  setTimeout(() => {
    document.querySelector("body").style.overflow = null;
  }, 1000);

  if (authStore.isLoggedIn) {
    router.push("/spaceport");
  }

  apiNode.value = configStore.apiNode;
  rpcNode.value = configStore.rpcNode;
});

</script>
