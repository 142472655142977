export const useWhitelist = [
  //my accounts
  // "d24bg.wam",
  // "primate.ssg",
  // "ilhlw.c.wam",
  // "waxpirate111",
  // "ssgrentals31",
  // "ssgrentals32",
  // "ssgrentals34",

  //yorprints
  // "lb1r4.wam",
  // "yorprints111",
  // "ssgrentals13",
  // "ssgrentals14",
  // "ssgrentals33",

  // starshipjedi / nath
  // "x4wki.c.wam",
  // "starshipjedi",
  // "ssgrentals11",
  // "ssgrentals12",

  //crafty
  // "bisyq.wam",
  // "ssgrentals15",
  // "ssgrentals21",

  // Beatzmatrix
  // "kz3.q.c.wam",
  // "ssgrentals22",

  // recon jon
  // "rsihe.wam",
  // "ssgrentals24",
  // "ssgrentals25",

  // Matt
  // "m34fy.wam",

  //Atila
  // "sevenfourdev",

  //stu
  // "t.dvy.wam",

  //soag
  // "ppbg.wam",
  // "3ur.m.c.wam",
  // "ioh.4.c.wam",
  // "tejla.c.wam",
  // "puj1a.c.wam",
  // "ns2kg.c.wam",
  // "41q2m.c.wam",
  // "tppmm.c.wam",
  // "dkjmm.c.wam",
  // "mtpmm.c.wam",
  // "uzsn..c.wam",
  // "nbv3..c.wam",
  // "2i2k..c.wam",

];