<template>
  <Modal
    id="enhancePlanetModal"
    :title="modalTitle"
    :on-close="closeModal"
    class=""
    size-class="modal-xl modal-fullscreen-lg-down"
    :use-footer="false"
  >
    <template #body>

      <div class="position-relative" style="min-height: 200px;" :class="{'max-height-on-loading':isLoading}" v-if="activated">

        <div class="d-flex mb-3">
          <button class="btn btn-outline-secondary ms-auto" @click="reloadPlayerNFTs"><i class="bi bi-arrow-clockwise"></i> Reload NFTs</button>
          <button class="btn ms-2" :class="{'btn-primary':showAll, 'btn-outline-primary':!showAll}" @click="toggleShowAll"><i class="bi bi-eye-fill"></i> Show All Enhancements</button>
        </div>

        <div class="row">
          <div class="col-sm-6 d-none d-sm-block">
            <div style="max-width: 150px;">
              <img :src="`/images/planets/${targetPlanet.type}.webp`" class="img-fluid grow" :alt="targetPlanet.type">
            </div>
          </div>
          <div class="col-sm-6">
            <table class="table table-striped table-borderless table-dark">
              <tbody>
              <tr>
                <th>Planet</th>
                <td>{{planetsStore.planetName(planetId)}}</td>
              </tr>
              <tr>
                <th>Type</th>
                <td>{{targetPlanet.type}}</td>
              </tr>
              <tr>
                <th>Current Max Pool</th>
                <td>{{helpers.round(targetPlanet.max_pool).toLocaleString()}}</td>
              </tr>
              <tr>
                <th>Max Possible Pool</th>
                <td>{{helpers.round(maxPool.max_pool).toLocaleString()}}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <hr>

        <h4>Available Enhancements</h4>
        <p class="help">Click on any enhancement to see your NFTs available.</p>
        <!-- Headings -->
        <div class="grid-planet-enhance-row heading-row text-bold mb-3">
          <div>Template</div>
          <div class="text-center">Increase</div>
          <div class="text-center">Auto-Return</div>
          <div class="text-end">NFTs</div>
          <div></div>
        </div>
        <!-- Rows/groups -->
        <PlanetEnhancementGroup
          v-for="enhancement in enhancements"
          :key="enhancement.template_id"
          :enhancement="enhancement"
          :template="templates.find(t => t.template_id === enhancement.template_id.toString())"
          :assets="playerAssets.filter(a => a.template.template_id === enhancement.template_id.toString()) || []"
          :show-all="showAll"
          @enhance="actionEnhancePlanet"
        ></PlanetEnhancementGroup>

        <div class="alert alert-info w-100" v-if="enhancements.length === 0">
          <p class="mb-0 text-center">No enhancements found!</p>
        </div>

        <div v-if="isLoading" class="loading-overlay text-center">
          <div class="lds-dual-ring mx-auto"></div>
          <p class="text-success text-bold">Loading...</p>
          <p class="text-success">{{ playerAssets.length }} NFTs</p>
        </div>
      </div>

    </template>

  </Modal>
</template>

<script setup>
import { computed, onMounted, onUnmounted, ref, watch } from "vue";
import Modal from "@/components/Modal.vue";
import { usePlayerActionsStore } from "@/stores/actions/PlayerActionsStore";
import { usePlanetsStore } from "@/stores/PlanetsStore";
import { useShipsStore } from "@/stores/ShipsStore";
import { useConfigStore } from "@/stores/ConfigStore";
import { useAuthStore } from "@/stores/AuthStore";
import bus from "@/eventBus";
import * as bootstrap from "bootstrap/dist/js/bootstrap.esm.min";
import { usePlanetEnhanceStore } from "@/stores/PlanetEnhanceStore";
import { useAtomicAssetsStore } from "@/stores/AtomicAssetsStore";
import PlanetEnhancementGroup from "@/components/modals/EnhancePlanetModal/PlanetEnhancementGroup.vue";
import helpers from "@/helpers/global-helpers";

const authStore = useAuthStore();
const configStore = useConfigStore();
const playerActionsStore = usePlayerActionsStore();
const shipsStore = useShipsStore();
const planetsStore = usePlanetsStore();
const planetEnhanceStore = usePlanetEnhanceStore();
const atomicAssetsStore = useAtomicAssetsStore();

let epModalElem;
let epModal;

const activated = ref(false);
const planetId = ref("");
const shipId = ref("");
const showAll = ref(false);

const isLoading = computed(() => atomicAssetsStore.isLoading);
const enhancements = computed(() => planetEnhanceStore.enhancements.filter(e => e.isactive === 1));
const playerAssets = computed(() => atomicAssetsStore.playerAssets.filter(a => a.used_for_enhancing === false));
const templates = computed(() => atomicAssetsStore.templates);
const targetPlanet = computed(() => planetsStore.planets[planetId.value]);
const maxPool = computed(() => planetEnhanceStore.maxPools.find(p => p.type === targetPlanet.value.type));

const modalTitle = computed(() => {
  let string = "Enhance Planet";
  if(planetId.value) {
    string += ` ${planetsStore.planetName(planetId.value)}`;
  }
  return string;
});

const closeModal = () => {
  epModal.hide();
};

const toggleShowAll = () => {
  showAll.value = !showAll.value;
};

const openModal = () => {
  if(!atomicAssetsStore.hasFetchedTemplates) {
    // console.log(enhancements.value.length+' Enhancements - now fetch templates');
    atomicAssetsStore.fetchTemplates(enhancements.value.map(e => e.template_id));
  }

  if(!atomicAssetsStore.hasFetchedAllPlayerAssets) {
    reloadPlayerNFTs();
  }
  showAll.value = false;
  epModal.show();
};

const reloadPlayerNFTs = () => {
  atomicAssetsStore.fetchAllPlayerAssets(authStore.wallet);
};

const actionEnhancePlanet = (assetId, enhancement) => {
  playerActionsStore.enhancePlanet(shipId.value, assetId, planetId.value)
    .then(() => {
      console.log("Planet Enhanced!")

      // Remove the asset from playerAssets
      removeAssetFromPlayerAssets(assetId);

      // queue an update to the planet pool/max_pool
      updatePlanetPool(planetId.value, enhancement) //TODO

    }).catch(error => {
    console.error("sub error", error)
  });
};

const removeAssetFromPlayerAssets = (assetId) => {
  // console.log("removeAssetFromPlayerAssets", assetId);

  const assetIndex = atomicAssetsStore.playerAssets.findIndex(a => a.asset_id === assetId);
  if(assetIndex >= 0) {
    // atomicAssetsStore.playerAssets.splice(assetIndex, 1); //Too slow if item is at start of playerAssets array
    atomicAssetsStore.playerAssets[assetIndex].used_for_enhancing = true;
  }
};

const updatePlanetPool = (planetId, enhancement) => {
  // console.log("updatePlanetPool", planetId, enhancement);

  let amount = 0;

 // calculate the expected increase in planet size and update our planet in the store (temporary until we fetch the planet after timeout)
  if(enhancement.amountorpercentage) {
    //percentage (get percentage of max pool)
    amount = parseFloat(enhancement.up_percentage) * parseFloat(maxPool.max_pool);
  } else {
    //flat amount
    amount = enhancement.up_amount;
  }

  if(amount > 0) {
    // Do not allow total greater than max pool
    if(targetPlanet.value.max_pool + amount < parseFloat(maxPool.value.max_pool)) {
      targetPlanet.value.max_pool = (parseFloat(targetPlanet.value.max_pool) + amount).toString();
    } else {
      targetPlanet.value.max_pool = maxPool.max_pool;
    }
  }

  setTimeout(() => {
    planetsStore.getPlanet(planetId);
  }, 5000);

  // early return if autoreturn is true
  if(enhancement.auto_return === 1) {
    shipsStore.manuallyUpdateReturningShip(planetId, shipId.value);
    setTimeout(() => {
      shipsStore.getShip(shipId.value);
    }, 5000);
    closeModal(); //since we cant
  }
};

onUnmounted(() => {
  bus.$off('openEnhancePlanetModal');
  bus.$off('closeEnhancePlanetModal');
});

onMounted(() => {
  if(process.env.NODE_ENV !== "production" && import.meta.env.VITE_SHOW_MOUNTED_DEBUG === "true") {
    console.debug("EnhancePlanetModal mounted");
  }

  bus.$on('openEnhancePlanetModal', (planet, ship) => {
    // console.log('bus.$on.openEnhancePlanetModal', planet, ship);
    planetId.value = planet;
    shipId.value = ship;
    activated.value = true;
    openModal();
  });

  bus.$on('closeEnhancePlanetModal', (args) => {
    // console.log('bus.$on.closeEnhancePlanetModal', args);
    closeModal();
  });

  epModalElem = document.getElementById('enhancePlanetModal')
  epModal = new bootstrap.Modal(document.getElementById('enhancePlanetModal'));

  // epModalElem.addEventListener('hidden.bs.modal', () => {planetId.value = ""});

  //Trigger fetching of the planet enhancements config
  planetEnhanceStore.fetchPlanetMaxPools();
  planetEnhanceStore.fetchEnhancements();

});
</script>
