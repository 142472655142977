export const useThemes = [
  {
    "name": "Default",
    "slug": "",
    "class": "theme-default"
  },
  // {
  //   "name": "Starship",
  //   "slug": "starship",
  //   "class": "theme-starship"
  // },
  // {
  //   "name": "Primate Pirate",
  //   "slug": "primate-pirate",
  //   "class": "theme-primate-pirate"
  // },
  // {
  //   "name": "Starship Gang",
  //   "slug": "ssg",
  //   "class": "theme-ssg"
  // }
];